import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginActions } from '../../action/loginAction';
// import { SetUserCurrency }  as '../../action/loginAction'; 


const Currencyselector = () => {
    const getCurrencyName = (name) => {
        try {
            let value = localStorage.getItem(name);
            let curr_val = process.env.REACT_APP_CURRENCY;
            if (value) {
                value = JSON.parse(value)
            }
            if (value) {
                return value.code;
            }
            else {
                let event = { 'code': 'AED', "symbol": "AED" };
                localStorage.setItem('setuserCuncy', JSON.stringify(event))
                return curr_val;
            }
        }
        catch (ex) {
            return process.env.REACT_APP_CURRENCY;
        }
    };

    const [selectedLang, setSelectedCurrency] = useState(getCurrencyName("setuserCuncy"));
    const CurrencyList = [{ 'code': 'USD', "symbol": "$" }, { 'code': 'AED', "symbol": "AED" }, { 'code': 'SAR', "symbol": "﷼" }, { 'code': 'PHP', "symbol": "₱" }, { 'code': 'THB', "symbol": "฿" }, { 'code': 'VND', "symbol": "₫" }, { 'code': 'IDR', "symbol": "Rp" }, { 'code': 'INR', "symbol": "₹" }, { 'code': 'MYR', "symbol": "RM" }, { 'code': 'BRL', "symbol": "R$" }, { 'code': 'OMR', "symbol": "﷼" }, { 'code': 'BHD', "symbol": ".د.ب" }];
    const dispatch = useDispatch();

    const changeCurrency = (event) => {
        let AvailableCurrn = localStorage.getItem('aedEqualCrnyValue');
        if (AvailableCurrn) {
            AvailableCurrn = JSON.parse(AvailableCurrn);
            if (AvailableCurrn && AvailableCurrn.length > 0) {
                let AvailCu = AvailableCurrn.filter(a => a.currency_desc == event.code);
                if (AvailCu.length == 0) {
                    let Obj = { 'code': 'AED', "symbol": "AED" };
                    StoredLocalStg(Obj)
                }
                else {
                    StoredLocalStg(event)
                }
            }
            else {
                let Obj = { 'code': 'AED', "symbol": "AED" };
                StoredLocalStg(Obj)
            }
        }
        else {
            let Obj = { 'code': 'AED', "symbol": "AED" };
            StoredLocalStg(Obj)
        }
        setTimeout(() => {
            window.location.reload();
        }, 100);  //for get some translation from db
    }
    useEffect(() => {
        window.setCurrencyDoggle();
    }, [])

    const StoredLocalStg = (event) => {
        dispatch(loginActions.SetUserCurrency(event))
        localStorage.setItem('setuserCuncy', JSON.stringify(event))
        setSelectedCurrency(event.code);
    }




    return (
        <div className="selLanguage" onChange={changeCurrency} id="lstCurrency">
            <a className="selLangBtn Selcurrency">
                <img src="./assets/img/icons/curency.png" />
                <span style={{ textTransform: 'uppercase' }} className="selectedLanguageName">{selectedLang}</span>
            </a>
            <ul className="selLangContCurr">
                {CurrencyList.map((x, inx1) => <>
                    <li><a onClick={() => changeCurrency(x)}><span>{x.code}</span></a></li>
                </>
                )}
            </ul>
        </div>
    )
}

export default Currencyselector;