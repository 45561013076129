import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const getCookie = (name) => {
  try {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    let lang_val = process.env.REACT_APP_DEFAULTLANGUAGE;
    if (parts.length === 2)
      lang_val = parts.pop().split(';').shift();
    console.log("selected lang : " + lang_val);
    return lang_val;
  }
  catch (ex) {
    return process.env.REACT_APP_DEFAULTLANGUAGE;
  }
};
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getCookie("gtlang"),
    backend: {
      loadPath: './assets/i18n/{{ns}}/{{lng}}.json?v10.16'
      //loadPath: './assets/i18n/{{ns}}/en.json?v0.2'
    },
    fallbackLng: process.env.REACT_APP_DEFAULTLANGUAGE,
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      // wait: true
      useSuspense: true
    }
  });


export default i18n;