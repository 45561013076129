import { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { history } from '../../helpers/history';
import { gameActions } from '../../action/GameAction';
import { connect } from 'react-redux';
class DownloadApp extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.props.GetUserratings(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            Userratings: props.Userratings
        }
    }

    componentDidUpdate(prevState, prevProps) {
        if (this.state.Userratings && this.state.Userratings !== prevState.Userratings && this.state.Userratings.length > 0) {
            setTimeout(() => {
                if (window.SetUserratingSlider) {
                    window.SetUserratingSlider()
                }
            }, 500);
        }
    }

    changeTab = (tabid) => {
        localStorage.setItem('tabId', tabid);
        history.push('/generalinfo');
    }

    ConterArray = (Count) => {
        let arrayEmpty = [];
        for (var i = 1; i <= Count; i++) {
            arrayEmpty.push([])
        }
        return arrayEmpty
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <>
                <div className="wrap1">
                    <section className="sustain padd-Top-0">
                        {/* Trees Image Section */}
                        {i18n.language === 'ur' ?
                            <div className="sustainCont">
                                <h2>پائیداری (سیکشن)</h2>
                                <p>یو اے ای گرین ایجنڈا 2030 حکومت کی رہنمائی میں کام کرنے والا اقدام ہے جس کا مقصد یہ ہے کہ متحدہ عرب امارات کو پائیدار ترقی میں عالمی رہنما بنایا جائے۔ گلف جیک پاٹ کو اس اقدام کا حصہ بننے پر فخر ہے اور ہمارا یہ عزم ہے کہ ہم اس سیارے کے لیے بہتر مستقبل تیار کرنے کی راہ .</p>
                                <p>میں اپنا کردار ادا کریں گے۔ ہمارا ماننا ہے کہ دنیا کو مزید قابل سکونت جگہ بنانے میں ہر شخص اپنا کردار ادا کرسکتا ہے اور یہ کہ اس بات کو یقینی بنانے کے لیے ہم سب کو مل جل کر کام کرنے کی ضرورت ہے کہ ہم آنے والی نسلوں کے لیے صحت مند سیارہ چھوڑیں۔.</p>
                                <button className="bg3" onClick={() => this.changeTab(8)}>مزید جانیں</button>
                            </div>
                            :
                            i18n.language === 'ml' ?
                                <div className="sustainCont">
                                    <h2> സുസ്ഥിരത </h2>
                                    <p>യുഎഇ ഗ്രീൻ അജണ്ട 2030 എന്നത് ഗവൺമെന്റിന്റെ നേതൃത്വത്തിലുള്ള ഒരു സംരംഭമാണ്, ഇത്യു എഇയെ സുസ്ഥിര വികസനത്തിൽ ആഗോള നേതാവാക്കി മാറ്റാൻ ലക്ഷ്യമിടുന്നു.ഈ സംരംഭത്തിന്റെ ഭാഗമാകുന്നതിൽ ഗൾഫ്ജാ ക്ക്പോട്ട് അഭിമാനിക്കുന്നു, കൂടാതെ ഈ ലോകത്തി ന്മികച്ച ഭാവി സൃഷ്ടിക്കാൻ ഞങ്ങളുടെ പങ്ക്ചെയ്യാൻ ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്.</p>
                                    <p>ലോകത്തെ കൂടുതൽ വാസയോഗ്യമായ സ്ഥലമാക്കി മാറ്റുന്നതിൽ എല്ലാവർക്കും പങ്കുണ്ട് , ഭാവിതലമുറയ്ക്കായി ആരോഗ്യകരമായ ഒരു ലോകം കെട്ടിപ്പെടുക്കുമെന്ന് ഉറപ്പാക്കാൻ നാമെല്ലാവരും ഒരുമിച്ച് പ്രവർത്തിക്കേണ്ടതുണ്ടെന്നും ഞങ്ങൾ വിശ്വസിക്കുന്നു.</p>
                                    <button className="bg3" onClick={() => this.changeTab(8)}>കൂടുതൽ അറിയുക</button>
                                </div> : i18n.language === "ar" ?
                                    <div className="sustainCont">
                                        <h2> الاستدامة (قسم)</h2>
                                        <p>الأجندة الخضراء لدولة الإمارات العربية المتحدة 2030 هي مبادرة تقودها الحكومة وتهدف إلى جعل دولة الإمارات العربية المتحدة رائدة عالميًا في مجال التنمية المستدامة. تفتخر غالف جاكبوت بكونها جزءًا من هذه المبادرة، ونحن ملتزمون بالقيام بدورنا لخلق مستقبل أفضل للكوكب.</p>
                                        <p>ونؤمن بأن كل شخص لديه دور يلعبه في جعل العالم مكانًا أكثر ملاءمة للعيش، وأنه يتعين على الجميع أن يعملوا معًا لضمان أن نترك كوكبًا صالحًا للأجيال القادمة.</p>
                                        <button className="bg3" onClick={() => this.changeTab(8)}>اعرف أكثر</button>
                                    </div> :
                                    <div className="sustainCont">
                                        <h2>Sustainability for all.</h2>
                                        <p>The UAE Green Agenda 2030 is a government-led initiative  that aims to make the UAE a global leader in sustainable development. Gulf Ticket is proud to be a part of this initiative, and we are committed to doing our part to create a better future for the planet.</p>
                                        <p>We believe that everyone has a part to play in making the world a more livable place, and that we all need to work together to ensure that we leave a healthy planet for future generations.</p>
                                        <button className="bg3" onClick={() => this.changeTab(8)}>Know More</button>
                                    </div>
                        }
                    </section>
                    {/* Show FeedBack Section */}
                    {this.state.Userratings && this.state.Userratings.length > 0 &&
                        <section className="hapyPlayers">
                            <div className="hapyPlayersHeading">
                                <h2 className="headding1 !float-none">{t('lblhomecomments')}</h2>
                                <p className="w-100 text-center">{`"${t('lblratingnotes')}"`}</p>
                                <div className="hapyPlayers-line"></div>
                            </div>
                            <div className="playerSlider bounceIn2 fast">
                                <ul className="slides">
                                    {this.state.Userratings.map((x, inxd) =>
                                        <li key={`l_${inxd}`}>
                                            <div className="playerSlideCont">
                                                {/* style={{ float: "left", margin: "0px 0 -5px 0" }} */}
                                                <span className='userratingslider' ></span>
                                                <span className='userratingmsg'></span>
                                                <span><img src="./assets/img/testimal.png" /></span>
                                                {/* <p><img src="./assets/img/icons/quote-1.png" alt='quote-1 icon' />{x.message}<img src="./assets/img/icons/quote-2.png" alt='quote-2 icon' /></p><span className='userratingmsg'></span> */}
                                                <p className='Usermsgclr'>{x.message}</p>
                                                <h3>{x.playername}</h3>
                                                <div className="starRateShow">
                                                    {this.ConterArray(x.points).map((Obj, inx) =>
                                                        <span key={`m_${inx}`} className="starActive">&#9733;</span>
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </section>
                    }
                    {/* Show Download App Section */}
                    {/* <section className="app"> */}
                    <div className="appCont flex items-center">
                        <div className="appContRht">
                            <div className="appSec">
                                <h3 className="headding2 headdingApp" dangerouslySetInnerHTML={{ __html: t(`lblDownLoadAppNow`) }}></h3>
                                <button><a target='_blank' href='https://play.google.com/store/apps/details?id=com.gulftickets'><img src="./assets/img/google-play.png?V0.1" /></a></button>
                            </div>
                            <div class="appSec">
                                <h3 class="headding2 headdingApp" dangerouslySetInnerHTML={{ __html: t(`lblLaunchApp`) }} ></h3>
                                <button><img src="./assets/img/app-store.png?V0.1" /></button>
                            </div>
                            {/* <img src="./assets/img/mobile.png?v0.2" alt='mobile image' /> */}
                        </div>
                        <div className="appContLft">
                            <img src="./assets/img/mobile.png?v0.4" alt='mobile image' />
                            {/* <div className="appSec" style={{ width: "40%" }}>
                                    <h3 className="headding2 headdingApp text-center">{t('lblDownLoadAppNow')}</h3>
                                    <button><a target='_blank' href='http://123.63.108.195/jdf1/gulfticket/gulfticketB43.apk'><img src="./assets/img/google-play.webp" /></a></button>
                                </div> */}
                            {/* style={{ width: "100%" }} */}
                            {/* <div className="appSec appdowmload" >
                                <h1 className="headding2  text-center">{t('lblLaunchApp')}</h1>
                                <button><img src="./assets/img/app-store.webp" /></button>
                                headdingApp
                            </div> */}
                        </div>
                    </div>
                    {/* </section > */}
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { latestResult, Userratings } = state.gameReducer;
    const { user, appvariables } = state.loginReducer;
    return { user, latestResult, appvariables, Userratings };
}
const mapActionToProps = {
    GetUserratings: gameActions.GetUserratings
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(DownloadApp))


