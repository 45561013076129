import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { loginActions } from "../../action/loginAction";
import { gameActions } from "../../action/GameAction";
import { history } from "../../helpers/history";
import { appVariables } from "../../helpers/appVariables";
class Changepassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentpwd: '',
            newpwd: '',
            confirmpwd: '',
            showoldpwd: false,
            shownewpwd: false,
            showconfrimpwd: false,
            lottoType: props.lottotype,
            showdDeleteMsg: false,
            creditStatus: 0
        }  
    }

    static getDerivedStateFromProps(props, state) {
        return {
            FavNo: props.FavNo
        }
    }

    handleChangePwd = (e) => {
        try { this.setState({ [e.target.name]: e.target.value }); }
        catch (ex) { }
    }

    PlayAgain = (gameNo) => {
        let gameID = gameNo.split(';').length == 5 ? 9 : 10;
        localStorage.setItem('setfavNo', gameNo);
        if (gameID == 9) {
            history.push('/fortune6');
        }
        else {
            history.push('/super6');
        }
    }


    // changePwd = () => {
    //     if (this.state.currentpwd && this.state.newpwd && this.state.confirmpwd && this.state.currentpwd.trim() != "" && this.state.newpwd.trim() != "" && this.state.confirmpwd.trim() != "") {
    //         if (this.state.currentpwd != this.state.newpwd) {
    //             let regEx = /^(?=.*?[0-9])(?=.*?[^0-9A-Za-z]).{8,15}$/;
    //             if (regEx.test(this.state.password)) {
    //                 // if (this.state.newpwd.length > 2 && this.state.confirmpwd.length > 2) {
    //                 if (this.state.newpwd == this.state.confirmpwd) {
    //                     this.state.currentpwd = this.state.currentpwd.replaceAll("'", "''");
    //                     this.state.newpwd = this.state.newpwd.replaceAll("'", "''");
    //                     this.props.changepassword(this.props.user.mobileNo, this.state.currentpwd, this.state.newpwd, appVariables.PASSWORD_FLAG.ChangePasswordFlag, this.props.i18n.language, this.props.user.loginKey);
    //                     this.setState({ currentpwd: '', newpwd: '', confirmpwd: '' });
    //                 }
    //                 else {
    //                     this.showMsg('lblPwdMismatch');
    //                 }
    //             }
    //             else { this.showMsg('lblReqPasswordPolicy'); }
    //             // else {
    //             //     this.showMsg('lblMinlen');
    //             // }
    //         }
    //         else {
    //             this.showMsg('lblCurrandNewPwdSame');
    //         }
    //     }
    //     else {
    //         this.showMsg('lblPleaseFillDet');
    //     }
    // }
    showMsg = (Msg) => {
        this.props.alertError(this.props.t(Msg));
    }
    componentDidMount() {
        //window.FavouritesPopUp()
        // localStorage.setItem('atabId', 5); this code is using in Account Component 
    }
    DeletefavNo = (id) => {
        this.props.DeletefavNo(id, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        this.state.showdDeleteMsg = true;
    }

    changePwd = () => {
        if (this.state.currentpwd && this.state.newpwd && this.state.confirmpwd && this.state.currentpwd.trim() != "" && this.state.newpwd.trim() != "" && this.state.confirmpwd.trim() != "") {
            if (this.state.currentpwd != this.state.newpwd) {
                if (this.state.newpwd == this.state.confirmpwd) {
                    let regEx = /^(?=.*?[0-9])(?=.*?[^0-9A-Za-z]).{8,15}$/;
                    if (regEx.test(this.state.newpwd) && regEx.test(this.state.confirmpwd)) {
                        this.state.currentpwd = this.state.currentpwd.replace(/'/g, "''");
                        this.state.newpwd = this.state.newpwd.replace(/'/g, "''");
                        this.props.changepassword(this.props.user.mobileNo, this.state.currentpwd, this.state.newpwd, appVariables.PASSWORD_FLAG.ChangePasswordFlag, this.props.i18n.language, this.props.user.loginKey);
                        this.setState({ currentpwd: '', newpwd: '', confirmpwd: '' });
                    }
                    else {
                        this.showMsg(this.props.t('lblReqPasswordPolicy'));
                    }
                }
                else {
                    this.showMsg(this.props.t('lblPwdMismatch'));
                }
            }
            else {
                this.showMsg(this.props.t('lblCurrandNewPwdSame'));
            }
        }
        else {
            this.showMsg(this.props.t('lblPleaseFillDet'));
        }
    }
            

    render() {
        const { t } = this.props;
        let myFavNo = this.state.FavNo;
        if (this.state.creditStatus != 0 && this.state.FavNo) {
            if (this.state.creditStatus == 1)
                myFavNo = this.state.FavNo.filter((x) => x.numbers.split(';').length == 5);
            else
                myFavNo = this.state.FavNo.filter((x) => x.numbers.split(';').length == 6);
        }
        return (
            <div className="profileCont">
                <div className="walletTrans">
                    <div className="col-md-6">
                        <div className="logReg walletTrans">
                            <div className="col-md-12">
                                <div className="changePsw">
                                    <h2>{t('lblChangepPwd')}</h2>
                                </div>
                            </div>
                            <div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('lblCuurentpwd')}<small className="requiredSymbol">*</small></label>
                                        <div className="PswImg">
                                            <span className="-ml-[2px] rtl:-mr-[2px]"><img src="./assets/img/icons/mail_send_02.webp" /></span>
                                            {/* {t('lblCuurentpwd')} */}
                                            <input className="currentPass h-[48px]" type={this.state.showoldpwd ? "text" : "password"} placeholder="********" id="txtCurrentPwd" name="currentpwd" value={this.state.currentpwd} onChange={(e) => this.handleChangePwd(e)} maxLength={15} />
                                            {this.state.showoldpwd ? <img src="./assets/img/icons/eyeopen.webp?ver=1.0" alt="show password icon" className="currentPassEye" onClick={() => this.setState({ showoldpwd: false })} /> : <img src="./assets/img/icons/eye-slash.webp?ver=1.0" alt="show password icon" className="currentPassEye" onClick={() => this.setState({ showoldpwd: true })} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('lblNewPwd')}<small className="requiredSymbol">*</small></label>
                                        <div className="PswImg">
                                            <span className="-ml-[2px] rtl:-mr-[2px]"><img src="./assets/img/icons/message_01.webp" /></span>
                                            {/* {t('lblNewPwd')} */}
                                            <input className="newPass h-[48px]"  type={this.state.shownewpwd ? "text" : "password"} placeholder="********" id="txtNewPwd" name="newpwd" value={this.state.newpwd} onChange={(e) => this.handleChangePwd(e)} maxLength={15} />
                                            {this.state.shownewpwd ? <img src="./assets/img/icons/eyeopen.webp?ver=1.0" alt="show password icon" className="currentPassEye" onClick={() => this.setState({ shownewpwd: false })} /> : <img src="./assets/img/icons/eye-slash.webp?ver=1.0" alt="show password icon" className="currentPassEye" onClick={() => this.setState({ shownewpwd: true })} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('lblConfirmpwd')}<small className="requiredSymbol">*</small></label>
                                        <div className="PswImg">
                                            <span className="-ml-[2px] rtl:-mr-[2px]"><img src="./assets/img/icons/circle_password.webp" /></span>
                                            {/* {t('lblConfirmpwd')} */}
                                            <input className="confPass h-[48px]" type={this.state.showconfrimpwd ? "text" : "password"} placeholder="********" id="txtConfirmPwd" name="confirmpwd" value={this.state.confirmpwd} onChange={(e) => this.handleChangePwd(e)} maxLength={15} />
                                            {this.state.showconfrimpwd ? <img src='./assets/img/icons/eyeopen.webp?ver=1.0' alt="show password icon" className="currentPassEye" onClick={() => this.setState({ showconfrimpwd: false })} /> : <img src='./assets/img/icons/eye-slash.webp?ver=1.0' alt="show password icon" className="currentPassEye" onClick={() => this.setState({ showconfrimpwd: true })} />}
                                        </div>
                                    </div>
                                </div>
                                <small style={{ color: "#b1b2ba", fontSize: "small" }}><i className="fa fa-info-circle" style={{ fontFamily: "fontawesome-webfont" }}></i> {t('lblPolicyInPwd')}</small>
                                <div className="payNowBtn accountBtn">
                                    <button className="btn2" onClick={() => this.changePwd()}>{t('lblUpdate')}</button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="favouritesSection">
                            <h2>{t('lblFav')}</h2>
                            <div className="favouritesAlign">
                                <p className="textContent">{t('lblClickTo')}</p>
                                <button className="favouriteBtn" onClick={() => { this.showFavPop() }} id="show-popup">{t('lblManageFav')}</button>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-6 accountSetImg">
                        <div className="walletCusImage">
                            <img src="./assets/img/account-set.webp" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { FavNo, ObjDeleteFavNo } = state.gameReducer;
    return { user, FavNo, ObjDeleteFavNo };
}
const mapActionToProps = {
    alertError: alertActions.error,
    changepassword: loginActions.changepassword,
    BackFromCart: gameActions.BackFromCart,
    DeletefavNo: gameActions.DeletefavNo
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Changepassword))