import { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { gameActions } from '../../action/GameAction';
import { history } from '../../helpers/history';
class LatestResults extends Component {
    constructor(props) {
        super(props);
        this.props.GetLatestResult(0, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        this.state = {
            VideoSecion: [],
            dataLoadCompleted: false
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            // latestResult: props.latestResult
            latestResult: props.latestResult && props.latestResult.map(function (ele) {
                return { ...ele, game_group_id_temp: ele.game_group_id == 12 || ele.game_group_id == 8 ? '9' : ele.game_group_id };
            })
        }
    }

    componentDidMount() {
        this.setjsonvideourl();

    }
    componentDidUpdate(prevState, prevProps) {
        if (this.state.dataLoadCompleted == false && this.state.latestResult && this.state.latestResult.length > 0) {
            this.state.dataLoadCompleted = true;
            // setTimeout(() => { 
            //     if (window.LatesResultSlide) {
            //         window.LatesResultSlide();
            //     }
            // }, 0);
            setTimeout(() => {
                window.SetBgimgbasedOnGame();
            }, 1000);
        }
    }
    GotoPreviourresult = (GameId) => {
        localStorage.setItem('drawtabId', 1);
        //GameId == 9 ? history.push('/fortune4draws') : GameId == 10 ? history.push('/super6draws') : history.push('/magic5draws');
        switch (GameId) {
            case "9":
                history.push('/fortune6draws')
                break;
            case "10":
                history.push('/super6draws')
                break;
            case "11":
                history.push('/magic5draws')
                break;
            case "13":
                history.push('/lucky1draws')
                break;
            case "14":
                history.push('/dream5draws')
                break;
            case "15":
                history.push('/rush3draws')
                break;
            default:
                history.push('/othersdraws')
                break;
        }
    }

    setjsonvideourl = () => {
        let hbanner = 0;
        let hbanner_old = localStorage.getItem('videovers');
        if (this.props.appvariables.bVersion) {
            let hbannerVer = this.props.appvariables.bVersion.split("~")
            if (hbannerVer.length > 0) {
                hbanner = hbannerVer[4];
                localStorage.setItem('videovers', hbanner);
            }
        }
        if (hbanner_old != hbanner) {
            var s = process.env.REACT_APP_CDN_URL + 'web/HomeWinnerVideo.json' + "?V" + hbanner;
            fetch(s).then(response => response.json()).then(data => {
                localStorage.setItem('videourl', JSON.stringify(data));
                this.setState({ VideoSecion: data })
                console.log("data video url", data)
                setTimeout(() => {
                    if (window.HomePageVideoSlider)
                        window.HomePageVideoSlider()
                }, 300);
            })
        } else {
            let Videourl = JSON.parse(localStorage.getItem('videourl'));
            this.setState({ VideoSecion: Videourl })
            console.log("data video url", Videourl)
            setTimeout(() => {
                if (window.HomePageVideoSlider)
                    window.HomePageVideoSlider()
            }, 300);
        }
    }

    latseresult = (Val, Obj) => {
        console.log('Value', Val)
    }


    render() {
        // if (this.state.latestResult && this.state.latestResult.length > 0) {
        //     setTimeout(() => {
        //         window.SetBgimgbasedOnGame();
        //     }, 100);
        // };
        const { t } = this.props;
        const _ggidLst = this.state.latestResult && [...new Set(this.state.latestResult.map(item => item.game_group_id))];
        return (
            <>
                {/* {!this.state.latestResult || this.state.latestResult.length == 0 ? */}
                {/* <div className="imgAdjust">
                    <img src="./assets/img/banner/Result-Banner.webp?v0.1" />
                  </div> :   */}
                <section className="ldResult" id="ldResult">
                    <div className="ldResultCont">
                        <div className="ldRsltSlide">
                            <ul className="slides">
                                {_ggidLst && _ggidLst.map((gx, ginxd) => <>{
                                    this.state.latestResult && this.state.latestResult.filter(a => a.game_group_id == gx).slice(0, 1).map((x, inxd) =>
                                        // this.state.latestResult.filter(obj => obj.game_group_id == gx).length <= 1 ?
                                        (gx != 13 && gx != 15) ?
                                            <li key={`bg_${inxd}_${ginxd}_${gx}`} id={`game${gx == 8 ? '9': gx}`}>
                                                <h2 className="headding6">{t('lblLatestDrawRes')}</h2>
                                                <div key={`g_${inxd}_`} className={`ldResultLi ${x.game_group_id != 13 ? 'flex-col' : ''}`} >  {/*w-1/2 xl:w-1/3 */}
                                                    {/* <h2>{x.draw_no}</h2> */}
                                                    <div className="ldRBallTopSec">
                                                        <div className='ldRLogo'>
                                                            {
                                                                x.game_group_id == 9 ? <img src="./assets/img/gamesBg/fortune-6-logo-white.png?v0.2" alt="Fortune 6 logo" className="h-12 w-24" /> :
                                                                    x.game_group_id == 10 ? <img src="./assets/img/gamesBg/super-6-logo-white.png?v0.2" alt="super 6 logo" className="h-12 w-24" /> :
                                                                        x.game_group_id == 11 ? <img src="./assets/img/gamesBg/magic-5-logo-white.png?v0.2" alt="magic 5 logo" className="h-12 w-24" /> :
                                                                            x.game_group_id == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo-white.png?v0.1?v0.1" alt="lucky1 logo" className="h-12 w-24" /> :
                                                                                x.game_group_id == 14 ? <img src="./assets/img/gamesBg/dream-5-logo-white.png?v0.1" alt="dream 5 logo" className="h-12 w-24" /> :
                                                                                    x.game_group_id == 15 ? <img src="./assets/img/gamesBg/pick-3-logo-white.png?v0.4" alt="Pick 3 logo" className="h-12 w-24" /> :
                                                                                        <img src="./assets/img/gamesBg/fortune-4-logo-white.png?v0.1" alt="fortune 5 logo" className="h-12 w-24" />
                                                            }
                                                        </div>
                                                        {x.game_group_id != 13 ?
                                                            <>
                                                                {/* <div className={x.win_nods.split(';').length == 5 ? "column5" : x.win_nods.split(';').length == 6 ? "column6" : "column4"}> */}
                                                                <div className="column5">
                                                                    {x.win_nods.split(';').map((r, ine) =>
                                                                        <span key={`h_${ine}_a${inxd}${r}`}>{r}</span>
                                                                    )}
                                                                </div>
                                                                <h3>{x.draw_date.replace('|', ' ')}</h3>
                                                            </>
                                                            : <>
                                                                <h3>{x.draw_date.replace('|', ' ')}</h3>
                                                                {x.win_nods && x.win_nods.split(';').map((r, inx) =>
                                                                    // <div className={`lckmyCartBall lckmyCartBallOrdrs border-game13 game-13-balls-${parseInt(2)} game-${x.gamegroupID}-active`} >
                                                                    <ul key={`i_${inx}_b${inxd}_${r}`} className="zodiacBallSec Latestlukyresult game-13-balls rowGap">
                                                                        <li id={`ball_${inx}_${inxd}`}>
                                                                            <a className={`game-13-balls-${parseInt(r)} game-13 border-radius10`}>
                                                                                <span>{r}</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                    // </div>
                                                                )}
                                                            </>}
                                                    </div>
                                                    {x.draw_time && !x.draw_time.startsWith("1~") ?
                                                        <div className="winrAmntDetls">
                                                            <table className="tble">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{t('lblWinnings')}</th>
                                                                        <th >{t('lblWinnersml')}</th>
                                                                    </tr>

                                                                    {x.draw_time.split('|').map((w, i) =>
                                                                        <tr key={`j_${i}_c${inxd}`}>
                                                                            <td>{w.split(',')[0]}</td>
                                                                            <td>{w.split(',')[1]}</td>
                                                                        </tr>
                                                                    )}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        x.draw_time && x.draw_time.startsWith("1~") ?
                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                <p className={`text-game${x.game_group_id_temp}`}>{t('lblresultprogress')}</p>
                                                            </div> :
                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                <p className={`text-game${x.game_group_id_temp}`}>{t('lblNoWinner')}</p>
                                                            </div>
                                                    }
                                                    {/* <h3>{x.draw_date.replace('|', ' ')}</h3> */}
                                                    <button onClick={() => { this.GotoPreviourresult(x.game_group_id) }}>{t('lblPrevRes')}</button>
                                                </div>
                                            </li>
                                            :
                                            gx == 13 ?
                                                <li key={gx} id={`game${gx}`}>
                                                    <h2 className="headding6">{t('lblLatestDrawRes')}</h2>
                                                    <div className={`ldResultLi forDisInBlock`} >
                                                        <div className="ldRBallTopSec">
                                                            <div className="ldRLogo">
                                                                <img src="./assets/img/gamesBg/lucky-1-logo-white.png?v0.1?v0.1" alt="lucky1 logo" className="h-12 w-24" />
                                                            </div>
                                                            <h3>{x.draw_date.split('|')[0]}</h3>
                                                        </div>
                                                        {this.state.latestResult.filter(a => a.game_group_id == gx).map((lx, linxd) =>
                                                            <div className="winrAmntDetlsContentDiv">
                                                                <div className="winrAmntDetlslft">
                                                                    {/* <h5>{lx.draw_date.split('|').length > 1 ? <> {x.draw_date.split('|')[1]}</> : <>{lx.draw_date.split('|')[0]}</>}</h5> */}
                                                                    <h5>{lx.draw_date.split('|').length > 1 && lx.draw_date.split('|')[1]}</h5>
                                                                    {lx.win_nods && lx.win_nods.split(';').map((lr, linx) =>
                                                                        <ul key={`i_${linx}_b${linxd}_${lr}`} className="zodiacBallSec Latestlukyresult game-13-balls rowGap">
                                                                            <li id={`ball_${linx}_${linxd}`}>
                                                                                <a className={`game-13-balls-${parseInt(lr)} game-13 border-radius10`}>
                                                                                    <span>{lr}</span>
                                                                                </a></li>
                                                                        </ul>
                                                                    )}
                                                                    {lx.draw_time && !lx.draw_time.startsWith("1~") ?
                                                                        <>
                                                                            <div className="winrAmntDetlsLucky">
                                                                                <ul class="winAmntAndCount">
                                                                                    {lx.draw_time.split('|').map((lw, li) =>
                                                                                        <>
                                                                                            <li>
                                                                                                <div>{t('lblWinnings')}: {lw.split(',')[0]}</div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div>{t('lblWinnersml')} : {lw.split(',')[1]}</div>
                                                                                            </li>
                                                                                        </>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        lx.draw_time && lx.draw_time.startsWith("1~") ?
                                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                                <p className={`text-game${lx.game_group_id_temp}`}>{t('lblresultprogress')}</p>
                                                                            </div> :
                                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                                <p className={`text-game${lx.game_group_id_temp}`}>{t('lblNoWinner')}</p>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/* <h3>{x.draw_date.split('|')[0]}</h3> */}
                                                        <button onClick={() => { this.GotoPreviourresult(gx) }}>{t('lblPrevRes')}</button>
                                                    </div>
                                                </li>
                                                :
                                                <li key={gx} id={`game${gx}`}>
                                                    <h2 className="headding6">{t('lblLatestDrawRes')}</h2>
                                                    <div className={`ldResultLi forDisInBlock`} >
                                                        <div className="ldRBallTopSec">
                                                            <div className="ldRLogo">
                                                                <img src="./assets/img/gamesBg/pick-3-logo-white.png?v0.5" alt="Pick 3 logo" className="h-12 w-24" />
                                                            </div>
                                                            <h3>{x.draw_date.split('|')[0]}</h3>
                                                        </div>
                                                        {this.state.latestResult.filter(a => a.game_group_id == gx).map((lx, linxd) =>
                                                            <div className="winrAmntDetlsContentDiv">
                                                                <div className="winrAmntDetlslft pick3Txt">
                                                                    {/* <h5>{lx.draw_date.split('|').length > 1 ? <> {x.draw_date.split('|')[1]}</> : <>{lx.draw_date.split('|')[0]}</>}</h5> */}
                                                                    <div class="pick3LdrHead">
                                                                        <h5>{lx.draw_date.split('|').length > 1 && lx.draw_date.split('|')[1]}</h5>
                                                                        <ul class="ballSec game-15-balls rowGap">
                                                                            {lx.win_nods && lx.win_nods.split(';').map((lr, linx) =>
                                                                                <li><a class="game-15-active">{lr}</a></li>
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                    {lx.draw_time && !lx.draw_time.startsWith("1~") ?
                                                                        <>
                                                                            <table class="tble tblePick3">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td>{t('lblWinnings')}</td>
                                                                                        <td >{t('lblWinnersml')}</td>
                                                                                    </tr>
                                                                                    {lx.draw_time.split('|').map((lw, li) =>
                                                                                        <tr>
                                                                                            <td>{lw.split(',')[0]}</td>
                                                                                            <td>{lw.split(',')[1]}</td>
                                                                                            <td>{lw.split(',')[2] ? lw.split(',')[2] : 0}</td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                            {/* <div className="winrAmntDetlsLucky">
                                                                                <ul class="winAmntAndCount">
                                                                                    {lx.draw_time.split('|').map((lw, li) =>
                                                                                        <>
                                                                                            <li>
                                                                                                <div>{t('lblWinnings')}: {lw.split(',')[0]}</div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div>{t('lblWinnersml')} : {lw.split(',')[1]}</div>
                                                                                            </li>
                                                                                        </>
                                                                                    )}
                                                                                </ul>
                                                                            </div> */}
                                                                        </>
                                                                        :
                                                                        lx.draw_time && lx.draw_time.startsWith("1~") ?
                                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                                <p className={`text-game${lx.game_group_id_temp}`}>{t('lblresultprogress')}</p>
                                                                            </div> :
                                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                                <p className={`text-game${lx.game_group_id_temp}`}>{t('lblNoWinner')}</p>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/* <h3>{x.draw_date.split('|')[0]}</h3> */}
                                                        <button onClick={() => { this.GotoPreviourresult(gx) }}>{t('lblPrevRes')}</button>
                                                    </div>
                                                </li>
                                    )
                                }</>)}
                            </ul>
                        </div>
                    </div>
                    {this.state.VideoSecion && this.state.VideoSecion.length > 0 &&
                        <>
                            <h2 className="headding6 ourwinner">{t('lblwinnersec')}</h2>
                            <div className="hmVideoSec">
                                <div className="hmVideoSlides">
                                    <ul className="slides">
                                        {this.state.VideoSecion && this.state.VideoSecion.length > 0 && this.state.VideoSecion.map((x, inxd) =>
                                            <>
                                                <li key={`k_${inxd}`}>
                                                    <iframe
                                                        width="100%"
                                                        height="auto"
                                                        title="YouTube video player"
                                                        // frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        className="rounded-md iframeborder"
                                                        allowFullScreen
                                                        src={x.videoUrl + '&rel=0'}
                                                    // style={{border:"0px"}}
                                                    >
                                                    </iframe>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                                <a className="hmMoreVideosBtn" onClick={() => { history.push('/winnersstory') }} >{t('lblmorevideos')}</a>
                            </div>
                        </>}
                </section>
            </>
        )
    }

}

function mapStateToProps(state) {
    const { latestResult } = state.gameReducer;
    const { user, appvariables } = state.loginReducer;
    return { user, latestResult, appvariables };
}
const mapActionToProps = {
    GetLatestResult: gameActions.GetLatestResult
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(LatestResults))

