import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginActions } from '../action/loginAction';
import { withTranslation } from 'react-i18next';
import { history } from '../helpers/history';
import { Link } from 'react-router-dom';
import { gameActions } from '../action/GameAction';
import { alertActions } from '../action/AlertAction';
import { appVariables } from '../helpers/appVariables';
import LangSelector from '../Pages/MyAccount/Languageselector';
import IdelSessionTimer from "../IdelSessionTimer";
import Currencyselector from '../Pages/MyAccount/Currencyselector';
import { commonClass } from '../helpers/commonClass';
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			settabind: 0,
			fgame: [],
			logoutflog: 0,
			menuID: ''
		}
	}

	getbal = () => {
		this.props.getBalance(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code)
	}
	showLogin = (lobjloginshow) => {
		this.props.setPage(lobjloginshow);
	};
	showAccount = () => {
		history.push('/myaccount')
	};
	changelink(menuid) {
		this.setState({ settabind: menuid });
	}
	componentDidUpdate() {
		//	window.Loginpopup(this.props.LightBoxID);

	}
	static getDerivedStateFromProps(props, state) {
		return { LightBoxID: props.LightBoxID };
	};
	componentDidMount() {
		document.querySelector('.toggle').addEventListener('click', function () {
			this.classList.toggle('activate');
		});
		this.changeMenu();
	}
	showMsg = (msg) => {
		this.props.alertError(this.props.t(msg));
	}
	logout = (id) => {
		this.setState({ logoutflog: id });
	}
	mylogout = () => {
		this.props.logout(this.props.user.mobileNo);
	}
	gotoMyCart = () => {
		//due to user can view empty cart.
		//if (this.props.cartItems && this.props.cartItems.length > 0) {
		if (this.props.cartItems && this.props.cartItems.length > 0) {
			history.push('/mycart');
		}
		else {
			this.showMsg(this.props.t('lblCartempty'));
		}
	}
	openNav = () => {
		window.openNav();
	}
	changeMenu = () => {
		try {
			let myloc = history.location.pathname;
			switch (myloc) {
				case "/home":
					this.setState({ menuID: appVariables.MENU_ID.Home });
					break;
				case "/fortune4":
					this.setState({ menuID: appVariables.MENU_ID.Fortune5 });
					break;
				case "/super6":
					this.setState({ menuID: appVariables.MENU_ID.Super6 });
					break;
				case "/promotion":
					this.setState({ menuID: appVariables.MENU_ID.Promotion });
					break;
				case "/faqs":
					this.setState({ menuID: appVariables.MENU_ID.Faqs });
					break;
				case "/HowtoPlay":
					this.setState({ menuID: appVariables.MENU_ID.HowtoPlay });
					break;
				case "/fortune4draws":
					this.setState({ menuID: appVariables.MENU_ID.Fortune5draw });
					break;
				case "/super6draws":
					this.setState({ menuID: appVariables.MENU_ID.Super6draw });
					break;
				case "/Login":
					this.setState({ menuID: appVariables.MENU_ID.Login });
					break;
				case "/Register":
					this.setState({ menuID: appVariables.MENU_ID.Register });
					break;
				case "/magic5draws":
					this.setState({ menuID: appVariables.MENU_ID.Magic5draw });
					break;
				case "/magic5":
					this.setState({ menuID: appVariables.MENU_ID.Magic5 });
					break;
				case "/othersdraws":
					this.setState({ menuID: appVariables.MENU_ID.Others });
					break;
				case "/winnersstory":
					this.setState({ menuID: appVariables.MENU_ID.PrevDrawVideos });
					break;
				case "/lucky1":
					this.setState({ menuID: appVariables.MENU_ID.Lucky1 });
					break;
				case "/lucky1draws":
					this.setState({ menuID: appVariables.MENU_ID.Lucky1draw });
					break;
				case "/dream5draws":
					this.setState({ menuID: appVariables.MENU_ID.Dream5draw });
					break;
				case "/dream5":
					this.setState({ menuID: appVariables.MENU_ID.Dream5 });
					break;
				case "/rush3":
					this.setState({ menuID: appVariables.MENU_ID.Pick3 });
					break;
				case "/rush3draws":
					this.setState({ menuID: appVariables.MENU_ID.Pick3draw });
					break;
			}
		} catch (ex) { }
	}
	gotoAccount = () => {
		localStorage.removeItem('atabId');
		history.push('/Account');
	}
	gotoprevdraw = (gameID) => {
		localStorage.removeItem('drawtabId');
		if (gameID == 9) {
			history.push('/fortune6draws')
		}
		else if (gameID == 10) {
			history.push('/super6draws')
		}
		else if (gameID == 11) {
			history.push('/magic5draws')
		}
		else if (gameID == 12) {
			history.push('/othersdraws')
		}
		else if (gameID == 13) {
			history.push('/winnersstory')
		}
		else if (gameID == 17) {
			history.push('/lucky1draws')
		}
		else if (gameID == 18) {
			history.push('/dream5draws')
		}
		else if (gameID == 21) {
			history.push('/rush3draws')
		}

	}
	render() {
		const { t } = this.props;
		return (
			<>
				{this.props.user && this.props.user.mobileNo != "0" && <IdelSessionTimer />}
				<header className="header bg4">
					<div className="wrap1 flex-row flexWrapCenter">
						<div className="headTopLft">
							<div className="toggle" onClick={() => this.openNav()}>
								<span className="line-toggle"></span>
								<span className="line-toggle"></span>
								<span className="line-toggle"></span>
							</div>
							<a className="logo" onClick={() => history.push('/home')}><img src="./assets/img/logo.png?v0.3" alt="GT Logo" /></a>
						</div>
						<div className="headTopCenter">
							<ul className={this.props.user && this.props.user.mobileNo != "0" ? "headTopMenu" : "headTopMenu gapNone"} id='mySidenav'>
								{/* {(this.props.user && this.props.user.mobileNo == "0") && <> */}
								<li><Link to="/Login" id="mobileMenu" className="login">{t('lblLogin')}</Link></li>
								<li><Link to="/Register" id="mobileMenu" className="newUser menuActive" >{t('lblCreateAcc')}</Link></li>
								{/* </>} */}
								{/* {(this.props.user && this.props.user.mobileNo != "0") && <>
								<li><a className="login" id="mobileMenu" href="#">Login</a></li>
								<li><a className="newUser menuActive" id="mobileMenu" href="#">Create Account</a></li> 
								</>} */}
								<li><a
									// className={(this.state.menuID == appVariables.MENU_ID.Home) ?
									className={(this.state.menuID == appVariables.MENU_ID.Fortune5 || this.state.menuID == appVariables.MENU_ID.Super6 || this.state.menuID == appVariables.MENU_ID.Magic5 || this.state.menuID == appVariables.MENU_ID.Dream5
										|| this.state.menuID == appVariables.MENU_ID.Lucky1 || this.state.menuID == appVariables.MENU_ID.Pick3
									) ?
										"nowrap menuActive" : "nowrap"}>{t('lblBuynow')}</a>
									<div className="dropdownMenu">
										<a onClick={() => history.push('/lucky1')} className={(this.state.menuID == appVariables.MENU_ID.Lucky1) ? "menuActive" : ""}>Lucky 1</a>
										<a onClick={() => history.push('/rush3')} className={(this.state.menuID == appVariables.MENU_ID.Pick3) ? "menuActive" : ""}>Rush 3</a>
										<a onClick={() => history.push('/dream5')} className={(this.state.menuID == appVariables.MENU_ID.Dream5) ? "menuActive" : ""}>Dream 5</a>
										<a onClick={() => history.push('/magic5')} className={(this.state.menuID == appVariables.MENU_ID.Magic5) ? "menuActive" : ""}>Magic 5</a>
										<a onClick={() => history.push('/fortune6')} className={(this.state.menuID == appVariables.MENU_ID.Fortune5) ? "menuActive" : ""}>Fortune 6</a>
										<a onClick={() => history.push('/super6')} className={(this.state.menuID == appVariables.MENU_ID.Super6) ? "menuActive" : ""}>Super 6</a>
									</div>
								</li>
								<li><a onClick={() => history.push('/HowtoPlay')} className={(this.state.menuID == appVariables.MENU_ID.HowtoPlay) ? "menuActive" : ""}>{t('lblHowToPlay')}</a></li>
								{/* <li><Link to="/HowtoPlay" className={(this.state.menuID == appVariables.MENU_ID.HowtoPlay) ? "menuActive" : ""}>{t('lblHowToPlay')}</Link></li> */}
								{/* <i className="fa fa-angle-down" aria-hidden="true"></i> */}
								<li><a className={(this.state.menuID == appVariables.MENU_ID.Fortune5 || this.state.menuID == appVariables.MENU_ID.Super6 || this.state.menuID == appVariables.MENU_ID.Magic5 || this.state.menuID == appVariables.MENU_ID.Home || this.state.menuID == appVariables.MENU_ID.Others || this.state.menuID == appVariables.MENU_ID.Others)}>{t('lblDraws')}</a>
									<div className="dropdownMenu">
										<a onClick={() => this.gotoprevdraw(17)} className={(this.state.menuID == appVariables.MENU_ID.Lucky1draw) ? "menuActive" : ""}>Lucky 1</a>
										<a onClick={() => this.gotoprevdraw(21)} className={(this.state.menuID == appVariables.MENU_ID.Pick3draw) ? "menuActive" : ""}>Rush 3</a>
										<a onClick={() => this.gotoprevdraw(18)} className={(this.state.menuID == appVariables.MENU_ID.Dream5draw) ? "menuActive" : ""}>Dream 5</a>
										<a onClick={() => this.gotoprevdraw(11)} className={(this.state.menuID == appVariables.MENU_ID.Magic5draw) ? "menuActive" : ""}>Magic 5</a>
										<a onClick={() => this.gotoprevdraw(9)} className={(this.state.menuID == appVariables.MENU_ID.Fortune5draw) ? "menuActive" : ""}>Fortune 6</a>
										<a onClick={() => this.gotoprevdraw(10)} className={(this.state.menuID == appVariables.MENU_ID.Super6draw) ? "menuActive" : ""}>Super 6</a>
										<a onClick={() => this.gotoprevdraw(12)} className={(this.state.menuID == appVariables.MENU_ID.Others) ? "menuActive" : ""}>{t('lblothers')}</a>
										<a onClick={() => this.gotoprevdraw(13)} className={(this.state.menuID == appVariables.MENU_ID.PrevDrawVideos) ? "menuActive" : ""}>{t('lblprevvideo')}</a>
									</div>
								</li>
								<li><a onClick={() => history.push('/promotion')} className={(this.state.menuID == appVariables.MENU_ID.Promotion) ? "menuActive" : ""}>{t('lblPromotion')}</a></li>
								{/* <li><a onClick={() => history.push('/faqs')} className={(this.state.menuID == appVariables.MENU_ID.Faqs) ? "menuActive" : ""}>{t('lblFAQs')}</a></li> */}
							</ul>
						</div>
						<div className="flex items-center justify-end gap-2 xl:gap-4 text-sm xl:text-base">
							<LangSelector />
							<Currencyselector />
							{(this.props.user && this.props.user.mobileNo != "0") &&
								<button className="cartIcon" onClick={() => { this.gotoMyCart() }}>
									<img src="./assets/img/icons/cart-icon.svg" alt="cart icon" />
									{this.props.cartItems ? this.props.cartItems.length : 0}
								</button>}
							{(this.props.user && this.props.user.mobileNo != "0") ?
								// <ul className="headTopMenu" id='mySidenav' style={{ width: "auto" }}>
								// 	<li>
								<div className="userDetails">
									<div className='userDetailsInner'>
										<div className="userName" style={{ textTransform: "none" }}>
											{/* {this.props.user.Player_name ? <a>Hi, {this.props.user.Player_name}</a> :
												<a>{this.props.user.mobileNo}</a>} */}
											{this.props.user.Player_name && <div>
												<div className="text-[#8e8e8e] text-[12px] uppercase">Hi, {this.props.user.Player_name}</div>
												<div className="text-brand uppercase text-sm flex items-center gap-x-2 font-bold">
													<img src="./assets/img/wallet_add_new_img.webp" alt="wallet" className="w-5" onClick={() => { localStorage.setItem('atabId', 3); history.push('/Account') }} />
													{/* <span>{process.env.REACT_APP_CURRENCY} {this.props.user.balance}</span> */}
													<span>{this.props.appvariables.currency} {commonClass.aedCrnyConvert(this.props.user.balance)}</span>
												</div>
											</div>}

											{/* <span>{t('lblCredit')} : {process.env.REACT_APP_CURRENCY} {this.props.user.balance}</span> */}
											{/* <span>{t('lblWin')} : {process.env.REACT_APP_CURRENCY} {this.props.user.winbalance}</span> */}
										</div>
										<div className="avatar">
											{this.props.user.Player_name && this.props.user.Player_name.length > 0 ?
												<>{this.props.user.Player_name.charAt(0).toUpperCase()}</> : <p>?</p>
											}
										</div>
										<div className="dropdownMenu">
											<a onClick={() => this.gotoAccount()} style={{ textAlign: "left" }}>{t('lblMyAccount')}</a>
											<a onClick={() => this.logout(1)} style={{ textAlign: "left" }}>{t('lblLogout')}</a>
										</div>
									</div>
								</div>
								// 	</li>
								// </ul>
								:
								<>
									<a onClick={() => history.push('/Login')} className="login loginBtn">{t('lblLogin')}</a>
									<a onClick={() => history.push('/Register')} className="newUser activeSignup" >{t('lblCreateAcc')}</a>
									{/* <Link to="/Login" className="login loginBtn">{t('lblLogin')}</Link>
									<Link to="/Register" className="newUser activeSignup" >{t('lblCreateAcc')}</Link> */}
								</>

							}
						</div>
					</div>
				</header >
				{
					this.state.logoutflog != 0 && <>
						<div style={{ transform: "translate(-50%, -50%)", display: "block" }} className="lightbox AlertpopUp">
							<div className="lightBox-wrap">
								<div className="userContent">
									<h2 style={{ padding: "10px" }}>{t('lblAreYousureLogout')}</h2>
									<div className="altBtns">
										<button className="bg6" onClick={() => { this.mylogout() }}>{t('lblYes')}</button>
										<button className="bg3" onClick={() => { this.logout(0) }}>{t('lblNo')}</button>
									</div>
								</div>
							</div>
						</div>
						<div className="overlaybetsilp" style={{ display: "block" }}></div></>
				}
			</>
		);
	}
}
function mapStateToProps(state) {
	const { user, LightBoxID ,appvariables} = state.loginReducer;
	const { cartItems } = state.gameReducer;
	return { user, LightBoxID, cartItems,appvariables };
}
const mapActionToProps = {
	login: loginActions.login,
	setPage: loginActions.setPage,
	getBalance: loginActions.getBalance,
	SetGameFamily: gameActions.SetGameFamily,
	alertError: alertActions.error,
	logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Header))
