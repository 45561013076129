import { appConstants } from "../helpers/actionType";


let allFamily = JSON.parse(localStorage.getItem('allFamily'));
allFamily = allFamily ? allFamily : [];
let cDraw = {};
let cFamily = {};

let mycart = JSON.parse(localStorage.getItem('mycart'));
mycart = mycart ? mycart : [];
let _mybet = null
try { _mybet = JSON.parse(localStorage.getItem('mybet')); } catch (ex) { }
_mybet = _mybet ? _mybet : null;

let pay_id = JSON.parse(localStorage.getItem('pay_id'));
pay_id = pay_id ? pay_id : 0;
let allgms = JSON.parse(localStorage.getItem('allgames')); // game loading performance changes.
allgms = allgms ? allgms : [];
const initialState = {
  currentGamesFamily: cFamily, currentAvailableDraws: allgms, currentDraw: cDraw, // games: allFamily 
  latestResult: [], SlotGame: [], cartItems: mycart, PopularGames: [],
  OddsConfiglst: {}, myBet: _mybet, payTypeID: pay_id, MyFavNos: [], myReBet: null, previousResult: null, FavNo: []
};
export const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.GET_GAMEFAMILY_SUCCESS:
      return {
        ...state,
        games: action.games
      };
    case appConstants.GET_GAMEFAMILY_ERROR:
      return {
        ...state,
        games: []
      };
    case appConstants.SET_GAMEFAMILY:
      return {
        ...state,
        currentGamesFamily: action.gFamily
      };
    case appConstants.GET_AVAILDRAWS_SUCCESS:
      localStorage.setItem('allgames', JSON.stringify(action.currentAvailableDraws)); //  game loading performance changes.
      return {
        ...state,
        currentAvailableDraws: action.currentAvailableDraws
      };
    case appConstants.GET_AVAILDRAWS_ERROR:
      return {
        ...state,
        currentAvailableDraws: []
      };
    case appConstants.BUY_SUCCESS:
      localStorage.setItem('mybet', JSON.stringify(action.myBet))
      return {
        ...state,
        myBet: action.myBet
      };
    case appConstants.BUY_ERROR:
      localStorage.removeItem('mybet')
      return {
        ...state,
        myBet: null
      };
    case appConstants.GET_LATEST_RESULT_SUCCESS:
      return {
        ...state,
        latestResult: action.lResult
      };
    case appConstants.GET_LATEST_RESULT_ERROR:
      return {
        ...state,
        latestResult: null
      };

    case appConstants.ADD_CARTITEM:
      let lst = [...state.cartItems, action.cItem];
      localStorage.setItem('mycart', JSON.stringify(lst));
      //for reload cart value
      localStorage.removeItem('lastloginmycart');
      return {
        ...state,
        cartItems: [...state.cartItems, action.cItem]
      };
    case appConstants.UPDATE_CARTITEM:
      let lst3 = [...state.cartItems.filter(a => a.GamePadId != action.Items.GamePadId || a.gamegroupID != action.Items.gamegroupID), { ...action.Items }].sort((a, b) => (a.bMax > b.bMax) ? 1 : -1)
      localStorage.setItem('mycart', JSON.stringify(lst3))
      return {
        ...state,
        cartItems: lst3
      };
    case appConstants.REMOVE_CARTITEM:
      let lst1 = [...state.cartItems.filter(a => (a.GamePadId != action.cid || a.gamegroupID != action.gameID))]
      localStorage.setItem('mycart', JSON.stringify(lst1))
      return {
        ...state,
        cartItems: lst1
      };
    case appConstants.REMOVE_CLOSEDDRAW_CARTITEM:
      let lst2 = [...state.cartItems.filter(a => a.GameId != action.did)]
      localStorage.setItem('mycart', JSON.stringify(lst2))
      return {
        ...state,
        cartItems: lst2
      };
    case appConstants.SET_DRAW:
      return {
        ...state,
        currentDraw: action.cDraw
      };
    case appConstants.UPDATE_STAKE_ALL:
      return {
        ...state,
        stakeAll: action.stake
      };
    case appConstants.CLEAR_CARTITEM:
      localStorage.removeItem('mycart')
      return {
        ...state,
        cartItems: []
      };
    case appConstants.GET_CURRENT_RESULT:
      return {
        ...state,
        currentResult: action.cResult
      };
    case appConstants.GET_SLOT_GAMES:
      return {
        ...state,
        SlotGame: action.SlotGame
      };
    case appConstants.GET_REPRINT_DATA:
      return {
        ...state,
        myReBet: action.myReBet
      };
    case appConstants.GET_POPUPLAR_GAMES:
      return {
        ...state,
        PopularGames: action.PopularGames
      }
    case appConstants.GET_ODDS_CONFIGLIST:
      return {
        ...state,
        OddsConfiglst: action.OddsConfiglst
      }
    case appConstants.ORDER_PAYTYPE_ID:
      return {
        ...state,
        payTypeID: action.payid
      }
    case appConstants.My_FAV_NUMBERS:
      return {
        ...state,
        MyFavNos: action.favno
      }
    case appConstants.GET_PREVIOUS_RESULT_SUCCESS:
      return {
        ...state,
        previousResult: action.PrevResult
      };
    case appConstants.ANOTHER_GET_PREVIOUS_RESULT_SUCCESS:
      return {
        ...state,
        AnotherPrevResult: action.AlterPrevResult
      };
    case appConstants.GET_PREVIOUS_RESULT_ERROR:
      return {
        ...state,
        previousResult: null
      };
    case appConstants.ANOTHER_GET_PREVIOUS_RESULT_ERROR:
      return {
        ...state,
        AnotherPrevResult: null
      };

    case appConstants.GET_FAV_NUMBERS:
      return {
        ...state,
        FavNo: action.FavNo
      }
    case appConstants.DELETE_FAV_NUMBERS:
      return {
        ...state,
        ObjDeleteFavNo: action.dFavNo
      }
    case appConstants.GET_RAFFLE_RESULTS:
      return {
        ...state,
        RaffleResult: action.RaffleresultObj
      }
    case appConstants.GET_USER_RATINGS:
      return {
        ...state,
        Userratings: action.GetUser
      }
    case appConstants.GET_CURRENCY_VALUE:
      localStorage.setItem('aedEqualCrnyValue', JSON.stringify(action.GetCurrencyValue));
      return {
        ...state,
        Currencyvalue: action.GetCurrencyValue
      }
    default:
      return { ...state }
  }

}