import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { appVariables } from '../../helpers/appVariables';

class AboutMgicFive extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <section>
                <div className='wrap1'>
                    <div className="priceBraDwnTitle w-100">
                        {/* <h2>{i18n.language === 'ur' ? 'کے بارے میں ' : i18n.language === 'ml' ? 'നെകുറിച്ച്' : i18n.language === 'ar' ? 'حول اللعبة' :
                            i18n.language === 'th' ? 'เกี่ยวกับ' : i18n.language === 'tl' ? 'Tungkol sa' : i18n.language === 'es' ? 'Acerca de' :
                                i18n.language === 'ta' ? 'பற்றி' : i18n.language === 'hi' ? 'के बारे में' : 'About'} </h2> */}
                        <h2>{t('lblabouttxt')}</h2>
                        <img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 1 logo" className="w-30" />
                    </div>
                    <div class="w-100">
                        {i18n.language === 'ur' ?
                            <div id="about-me">
                                {/* <h2 className="aboutGameContentHead">کے بارے میں  <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBox1">
                                            <div>
                                                <div className="bg-game11">1</div>
                                            </div>
                                            <div className="bg-game11"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                            </div>

                                            <div className="text-center margin-top2  ">
                                                ہمارے ہفتہ وار ریفل ڈرا اور مین ڈرا میں ایک بار میں داخل ہونے کے لیے {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} اماراتی دینار میں بوتل خریدیں!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game11">2</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game11"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                            </div>
                                            <div className="text-center margin-top2  ">
                                                فی ٹکٹ 1 سے 36 کے درمیان کوئی بھی {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} نمبر منتخب کریں۔ یا، آپ رینڈمائزیشن "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" آئیکن پر کلک کر سکتے ہیں اور ہم آپ کے لیے آپ کے نمبر منتخب کرتے ہیں۔
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game11">3</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game11"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                            </div>
                                            <div className="text-center margin-top2  ">
                                                مین ڈرا:*{appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}  اماراتی دینار کا گرینڈ پرائز جیتنے کے لیے کسی بھی ترتیب میں انہیں میچ کریں۔
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game11">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game11"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                            </div>
                                            <div className="text-center margin-top2  ">
                                                ریفل ڈرا: ہر ہفتے {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} گارنٹی یافتہ فاتح! ہر فاتح AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} وصول کرتا ہے۔
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game11">5</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                        </div>
                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                            </div>
                                            <div className="text-center margin-top2  ">
                                                قرعہ اندازی ہفتہ وار جمعرات کو متحدہ عرب امارات کے وقت کے مطابق شام 8 بجے ہمارے یوٹیوب چینل اور فیس بک پیج پر لائیو ہو گی۔
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game11">6</div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                            </div>
                                            <div className="text-center margin-top2  ">
                                                گلف ٹکٹ پر پانی کی بوتل خرید کر آپ متحدہ عرب امارات میں کھجور کے درختوں کی باغبانی کی ترقی میں ہماری مدد کر رہے ہیں۔
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            i18n.language === 'ml' ?
                                <div id="about-me">
                                    {/* <h2 className="aboutGameContentHead">നെകുറിച്ച് <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBox1">
                                                <div>
                                                    <div className="bg-game11">1</div>
                                                </div>
                                                <div className="bg-game11"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                </div>

                                                <div className="text-center margin-top2  ">
                                                    ഞങ്ങളുപ്പട രപതിൊര റാഫിൾ ട്രഡാ ുിം പ്പമ ിൻ ട്രഡാ ുിം ഒറ്റ ടിക്ക് നൽകുന്നതിന് {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} ദിർഹത്തിന് ഒരു കുെി ൊങ്ങുക!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game11">2</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game11"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                </div>
                                                <div className="text-center margin-top2  ">
                                                    ഓരോ ടിക്കറ്റിനും 1 മുതൽ 36 വരെയുള്ള ഏതെങ്കിലും {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} നമ്പറുകൾ തിരഞ്ഞെടുക്കുക. അല്ലെങ്കിൽ, നിങ്ങൾക്ക് റാൻഡമൈസേഷൻ "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ഐക്കണിൽ ക്ലിക്ക് ചെയ്യാം, ഞങ്ങൾ നിങ്ങൾക്കായി നിങ്ങളുടെ നമ്പറുകൾ തിരഞ്ഞെടുക്കും.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game11">3</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game11"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                </div>
                                                <div className="text-center margin-top2  ">
                                                    രപധാന നറുപ്പക്കടുെ്: {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}*  ദിർഹത്തിനപ്പ്റ രഗാൻഡ് സരപസ് ട്നടുന്നതിന് ഏത് രകമത്തിലുിം അെ മാച്ച് പ്പെയ്യുക
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game11">4</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game11"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                </div>
                                                <div className="text-center margin-top2  ">
                                                    റാഫിൾ ഡ്രോ: എല്ലാ ആഴ്‌ചയും {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} വിജയികൾ ഉറപ്പ്! ഓരോ വിജയിക്കും {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} ദിർഹം ലഭിക്കും.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game11">5</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                            </div>
                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                </div>
                                                <div className="text-center margin-top2  ">
                                                    നറുക്കെടുപ്പ് ആഴ്ചതോറും വ്യാഴാഴ്ചകളിൽ യുഎഇ സമയം രാത്രി 8 മണിക്ക് ഞങ്ങളുടെ YouTube ചാനലിലും ഫേസ്ബുക്ക് പേജിലും തത്സമയം നടക്കും.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game11">6</div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9marginRht0 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                </div>
                                                <div className="text-center margin-top2  ">
                                                    ഗൾഫ് ടിക്കറ്റിൽ ഒരു ൊേർ കുെി ൊങ്ങുന്നതിലൂപ്പട, ുസണറ്റഡ് അറബ് എമിട്ററ്റിപ്പല ഈന്തെന ട്താേങ്ങളുപ്പട െികസനത്തിൽ നിങ്ങൾ ഞങ്ങപ്പള സഹാ ിക്കുന്നു.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                i18n.language === 'ar' ?
                                    <div id="about-me">
                                        {/* <h2 className="aboutGameContentHead">حول اللعبة <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBox1">
                                                    <div>
                                                        <div className="bg-game11">1</div>
                                                    </div>
                                                    <div className="bg-game11"></div>
                                                </div>
                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                    </div>

                                                    <div className="text-center margin-top2  ">
                                                        قم بشراء زجاجة بقيمة {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} دراهم للدخول في السحب الأسبوعي والسحب الرئيسي دفعة واحدة!
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game11">2</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game11"></div>
                                                </div>
                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                    </div>
                                                    <div className="text-center margin-top2  ">
                                                        اختر أي {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} أرقام بين 1 إلى 36 لكل تذكرة. أو يمكنك النقر على أيقونة التوزيع العشوائي "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" وسنختار أرقامك لك.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game11">3</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game11"></div>
                                                </div>
                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                    </div>
                                                    <div className="text-center margin-top2  ">
                                                        السحب الرئيسي: قم بمطابقتها بأي ترتيب للفوز بالجائزة الكبرى بقيمة *{appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize} ملین کا  إماراتي
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game11">4</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game11"></div>
                                                </div>
                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                    </div>
                                                    <div className="text-center margin-top2  ">
                                                        : {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} فائزين مضمونين كل أسبوع! يحصل كل فائز على {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} درهم إماراتي.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game11">5</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                </div>
                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                    </div>
                                                    <div className="text-center margin-top2  ">
                                                        سيتم إجراء السحوبات أسبوعياً أيام الخميس الساعة 8 مساءً بتوقيت الإمارات مباشرة على قناتنا على اليوتيوب وصفحتنا على الفيسبوك.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game11">6</div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                    </div>
                                                    <div className="text-center margin-top2  ">
                                                        من خلال شراء زجاجة مياه من موقع تذكرة الخليج، فإنك تساعدنا في تطوير مزارع أشجار النخيل في دولة الإمارات العربية المتحدة.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    i18n.language === 'es' ?
                                        <div id="about-me">
                                            {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBox1">
                                                        <div>
                                                            <div className="bg-game11">1</div>
                                                        </div>
                                                        <div className="bg-game11"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                        </div>

                                                        <div className="text-center margin-top2  ">
                                                            ¡Compre una botella por AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} para participar en nuestra RIFA semanal y en nuestra SORTEO PRINCIPAL de una sola vez!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game11">2</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game11"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                        </div>
                                                        <div className="text-center margin-top2  ">
                                                            Elija {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} números cualesquiera entre 1 y 36 por boleto.
                                                            O puede hacer clic en el ícono de aleatorización "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" y
                                                            nosotros seleccionaremos sus números por usted.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game11">3</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game11"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                        </div>
                                                        <div className="text-center margin-top2  ">
                                                            Cuadro principal: combínelos en CUALQUIER ORDEN para ganar el gran premio de {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}* AED.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game11">4</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game11"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                        </div>
                                                        <div className="text-center margin-top2  ">
                                                            Sorteo: ¡{appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} ganadores garantizados cada semana! Cada ganador recibe {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} AED.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game11">5</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                    </div>
                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                        </div>
                                                        <div className="text-center margin-top2  ">
                                                            Los sorteos se realizan semanalmente los jueves a las 8 P.M, hora de los Emiratos Árabes Unidos, en vivo en nuestro canal de YouTube y página de Facebook.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game11">6</div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                        </div>
                                                        <div className="text-center margin-top2  ">
                                                            Al comprar una botella de agua en Gulf Ticket, nos estás ayudando
                                                            en el desarrollo de plantaciones de palmeras en los Emiratos Árabes
                                                            Unidos.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        i18n.language === 'th' ?
                                            <div id="about-me">
                                                {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBox1">
                                                            <div>
                                                                <div className="bg-game11">1</div>
                                                            </div>
                                                            <div className="bg-game11"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                            </div>

                                                            <div className="text-center margin-top2  ">
                                                                ซื้อขวดในราคา {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} เดอร์แฮมสหรัฐ เพื่อเข้าร่วมการจับรางวัลรายสัปดาห์และการจับรางวัลหลักในครั้งเดียว!
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game11">2</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game11"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                            </div>
                                                            <div className="text-center margin-top2  ">
                                                                เลือก {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} หมายเลขใดก็ได้ระหว่าง 1 ถึง 36 ต่อตั๋ว
                                                                หรือคุณสามารถคลิกที่ไอคอน "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" แบบสุ่ม แล้วเราจะเลือกหมายเลขของคุณให้คุณ
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game11">3</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game11"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                            </div>
                                                            <div className="text-center margin-top2  ">
                                                                การจับฉลากหลัก: จับคู่พวกมันในลำดับใดก็ได้เพื่อรับรางวัลใหญ่ AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}*
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game11">4</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game11"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                            </div>
                                                            <div className="text-center margin-top2  ">
                                                                การจับฉลาก: รับประกันผู้ชนะ {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} รายทุกสัปดาห์! ผู้ชนะแต่ละคนจะได้รับ AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game11">5</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                        </div>
                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                            </div>
                                                            <div className="text-center margin-top2  ">
                                                                การออกรางวัลจะมีขึ้นทุกสัปดาห์ในวันพฤหัสบดี เวลา 8 น. ตามเวลาสหรัฐอาหรับเอมิเรตส์ ถ่ายทอดสดทางช่อง YouTube และหน้า Facebook ของเรา
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game11">6</div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                            </div>
                                                            <div className="text-center margin-top2  ">
                                                                การซื้อขวดน้ำจาก Gulf Ticket จะถือว่าคุณช่วยเราในการพัฒนาสวนต้นปาล์มในสหรัฐอาหรับเอมิเรตส์
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            i18n.language === 'tl' ?
                                                <div id="about-me">
                                                    {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBox1">
                                                                <div>
                                                                    <div className="bg-game11">1</div>
                                                                </div>
                                                                <div className="bg-game11"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                                </div>

                                                                <div className="text-center margin-top2  ">
                                                                    Bumili ng Bote para sa AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} para makapasok sa aming lingguhang RAFFLE DRAW at MAIN DRAW nang sabay-sabay!
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game11">2</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game11"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                                </div>
                                                                <div className="text-center margin-top2  ">
                                                                    Pumili ng anumang {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} numero sa pagitan ng 1 hanggang 36 bawat tiket. O,
                                                                    maaari kang mag-click sa icon ng randomization na "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" at piliin
                                                                    namin ang iyong mga numero para sa iyo.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game11">3</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game11"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                                </div>
                                                                <div className="text-center margin-top2  ">
                                                                    Pangunahing Draw: Itugma sila sa ANUMANG ORDER para manalo ng Grand Prize na AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}*.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game11">4</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game11"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                                </div>
                                                                <div className="text-center margin-top2  ">
                                                                    Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Garantiyang Mananalo bawat linggo! Ang bawat mananalo ay tumatanggap ng AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game11">5</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                            </div>
                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                                </div>
                                                                <div className="text-center margin-top2  ">
                                                                    Ang mga draw ay ginaganap linggu-linggo tuwing Huwebes sa 8 PM UAE Time, Live sa aming YouTube channel at Facebook page.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game11">6</div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                                </div>
                                                                <div className="text-center margin-top2  ">
                                                                    Sa pamamagitan ng pagbili ng bote ng tubig sa Gulf Ticket, tinutulungan mo kami sa pagbuo ng mga plantasyon ng palm tree sa United Arab Emirates.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                i18n.language === 'ta' ?
                                                    <div id="about-me">
                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBox1">
                                                                    <div>
                                                                        <div className="bg-game11">1</div>
                                                                    </div>
                                                                    <div className="bg-game11"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                                    </div>

                                                                    <div className="text-center margin-top2  ">
                                                                        எங்கள் வாராந்திர ரேஃபிள் டிரா மற்றும் மெயின் டிராவில் ஒரே நேரத்தில் நுழைய AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} க்கு ஒரு பாட்டிலை வாங்கவும்!
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game11">2</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                                    </div>
                                                                    <div className="text-center margin-top2  ">
                                                                        ஒரு டிக்கெட்டுக்கு 1 முதல் 36 வரை ஏதேனும் {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} எண்களைத் தேர்வு செய்யவும். அல்லது,
                                                                        நீங்கள் ரேண்டமைசேஷன் "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ஐகானைக் கிளிக் செய்யலாம், உங்களுக்கான
                                                                        எண்களை நாங்கள் தேர்ந்தெடுக்கிறோம்.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game11">3</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                                    </div>
                                                                    <div className="text-center margin-top2  ">
                                                                        முதன்மை டிரா: AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}* கிராண்ட் பரிசை வெல்ல, எந்த வரிசையிலும் அவற்றைப் பொருத்தவும்.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game11">4</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                                    </div>
                                                                    <div className="text-center margin-top2  ">
                                                                        ரேஃபிள் டிரா: ஒவ்வொரு வாரமும் {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} வெற்றியாளர்கள் உறுதி! ஒவ்வொரு வெற்றியாளரும் AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} பெறுகிறார்கள்.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game11">5</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                </div>
                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                                    </div>
                                                                    <div className="text-center margin-top2  ">
                                                                        வாரந்தோறும் வியாழக்கிழமைகளில் ஐக்கிய அரபு எமிரேட்ஸ் நேரப்படி இரவு 8 மணிக்கு டிராக்கள்
                                                                        நடைபெறும், எங்கள் YouTube சேனல் மற்றும் பேஸ்புக் பக்கத்தில் நேரலை.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game11">6</div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                                    </div>
                                                                    <div className="text-center margin-top2  ">
                                                                        வளைகுடா பயணச்சீட்டில் தண்ணீர் பாட்டிலை வாங்குவதன் மூலம், ஐக்கிய அரபு
                                                                        எமிரேட்ஸில் பனை மரத் தோட்டங்களை மேம்படுத்த எங்களுக்கு உதவுகிறீர்கள்.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    i18n.language === 'hi' ?
                                                        <div id="about-me">
                                                            {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBox1">
                                                                        <div>
                                                                            <div className="bg-game11">1</div>
                                                                        </div>
                                                                        <div className="bg-game11"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                                        </div>

                                                                        <div className="text-center margin-top2  ">
                                                                            हमारे साप्ताहिक रैफ़ल ड्रा और मुख्य ड्रा में एक बार में प्रवेश करने के लिए AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} में एक बोतल खरीदें!
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game11">2</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game11"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                                        </div>
                                                                        <div className="text-center margin-top2  ">
                                                                            प्रति टिकट 1 से 36 के बीच कोई भी {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} संख्याएँ चुनें। या, आप रैंडमाइज़ेशन "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" आइकन पर क्लिक कर सकते हैं और हम आपके लिए आपके नंबर चुन सकते हैं।
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game11">3</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game11"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                                        </div>
                                                                        <div className="text-center margin-top2  ">
                                                                            मुख्य ड्रा: AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}* का भव्य पुरस्कार जीतने के लिए किसी भी क्रम में उनका मिलान करें।
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game11">4</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game11"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                                        </div>
                                                                        <div className="text-center margin-top2  ">
                                                                            रैफ़ल ड्रा: हर सप्ताह {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} निश्चित विजेता! प्रत्येक विजेता को AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} मिलते हैं
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game11">5</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                                        </div>
                                                                        <div className="text-center margin-top2  ">
                                                                            ड्रॉ साप्ताहिक रूप से गुरुवार को रात 8 बजे संयुक्त अरब अमीरात समय पर होंगे, हमारे यूट्यूब चैनल और फेसबुक पेज पर लाइव होंगे।
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game11">6</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                                        </div>
                                                                        <div className="text-center margin-top2  ">
                                                                            गल्फ टिकट पर पानी की बोतल खरीदकर, आप संयुक्त अरब अमीरात में ताड़ के पेड़ के बागानों के विकास में हमारी सहायता कर रहे हैं।
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        i18n.language === 'vi' ?
                                                            <div id="about-me">
                                                                {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBox1">
                                                                            <div>
                                                                                <div className="bg-game11">1</div>
                                                                            </div>
                                                                            <div className="bg-game11"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                                            </div>

                                                                            <div className="text-center margin-top2  ">
                                                                                Mua một chai nước với giá {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} AED để tham gia XỔ SỐ và XỔ SỐ CHÍNH của chúng tôi chỉ trong một lần!
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game11">2</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game11"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                                            </div>
                                                                            <div className="text-center margin-top2  ">
                                                                                Chọn bất kỳ {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} số nào từ 1 đến 36 cho mỗi vé. Hoặc, bạn có thể nhấp vào biểu tượng "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" và chúng tôi sẽ chọn các số cho bạn.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game11">3</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game11"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                                            </div>
                                                                            <div className="text-center margin-top2  ">
                                                                                Xổ Số Chính: Khớp các số theo bất kỳ thứ tự nào để giành Giải Thưởng Lớn trị giá {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize} AED*
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game11">4</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game11"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                                            </div>
                                                                            <div className="text-center margin-top2  ">
                                                                                Xổ Số: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Người Chiến Thắng Được Đảm Bảo mỗi tuần! Mỗi người chiến thắng nhận được {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} AED.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game11">5</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                                            </div>
                                                                            <div className="text-center margin-top2  ">
                                                                                Rút thăm diễn ra hàng tuần vào Thứ Năm lúc 8 giờ tối theo Giờ UAE, Trực tiếp trên kênh YouTube và trang Facebook của chúng tôi.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game11">6</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                                            </div>
                                                                            <div className="text-center margin-top2  ">
                                                                                Bằng cách mua một chai nước trên Gulf Ticket, bạn đang hỗ trợ chúng tôi trong việc phát triển các đồn điền cây cọ ở Các Tiểu vương quốc Ả Rập Thống nhất.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            i18n.language === 'id' ?
                                                                <div id="about-me">
                                                                    {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBox1">
                                                                                <div>
                                                                                    <div className="bg-game11">1</div>
                                                                                </div>
                                                                                <div className="bg-game11"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                                                </div>

                                                                                <div className="text-center margin-top2  ">
                                                                                    Beli satu botol seharga AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} untuk mengikuti UNDIAN RAFFLE dan UNDIAN UTAMA mingguan kami sekaligus!
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game11">2</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game11"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                                                </div>
                                                                                <div className="text-center margin-top2  ">
                                                                                    Pilih {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} nomor apa saja antara 1 hingga 36 per tiket. Atau, Anda dapat mengklik ikon "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" dan kami akan memilih nomor untuk Anda.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game11">3</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game11"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                                                </div>
                                                                                <div className="text-center margin-top2  ">
                                                                                    Undian Utama: Cocokkan nomor dalam URUTAN APA PUN untuk memenangkan Hadiah Utama sebesar {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize} AED*
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game11">4</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game11"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                                                </div>
                                                                                <div className="text-center margin-top2  ">
                                                                                    Undian Raffle: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Pemenang Dijamin setiap minggu! Setiap pemenang menerima {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} AED.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game11">5</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                                                </div>
                                                                                <div className="text-center margin-top2  ">
                                                                                    Pengundian dilakukan setiap minggu pada hari Kamis pukul 8 malam Waktu UEA, Langsung di saluran YouTube dan halaman Facebook kami.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game11">6</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                                                </div>
                                                                                <div className="text-center margin-top2  ">
                                                                                    Dengan membeli satu botol air di Gulf Ticket, Anda membantu kami dalam pengembangan perkebunan pohon palem di Uni Emirat Arab.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                i18n.language === 'km' ?
                                                                    <div id="about-me">
                                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBox1">
                                                                                    <div>
                                                                                        <div className="bg-game11">1</div>
                                                                                    </div>
                                                                                    <div className="bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                                                    </div>

                                                                                    <div className="text-center margin-top2  ">
                                                                                        ទិញដបទឹកមួយក្នុងតម្លៃ {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} AED ដើម្បីចូលរួមក្នុងការចាប់ឆ្នោត RAFFLE និងឆ្នោតចម្បងប្រចាំសប្តាហ៍របស់យើងក្នុងពេលតែមួយ!
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">2</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        ជ្រើសរើស {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} លេខណាមួយពី 1 ដល់ 36 ក្នុងមួយសំបុត្រ។ ឬអ្នកអាចចុចលើរូបតំណាង "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ដោយចៃដន្យ ហើយយើងនឹងជ្រើសរើសលេខសម្រាប់អ្នក។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">3</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        ឆ្នោតចម្បង៖ ផ្គូផ្គងតាមលំដាប់ណាក៏បានដើម្បីឈ្នះរង្វាន់ធំ {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize} AED*
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">4</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        ឆ្នោត Raffle៖ មានអ្នកឈ្នះចំនួន {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} រូបដែលធានាជាថេរ​នៅ​រៀងរាល់សប្ដាហ៍! អ្នកឈ្នះនីមួយៗទទួលបាន {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} AED។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">5</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        ការចាប់រង្វាន់ធ្វើឡើងរៀងរាល់សប្តាហ៍នៅថ្ងៃព្រហស្បតិ៍ វេលាម៉ោង 8 យប់ ម៉ោងនៅ UAE ផ្សាយផ្ទាល់នៅលើបណ្តាញ YouTube និងទំព័រ Facebook របស់យើង។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">6</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        ដោយការទិញដបទឹកមួយលើ Gulf Ticket អ្នកកំពុងជួយគាំទ្រយើងក្នុងការអភិវឌ្ឍដំណាំដើមត្នោតនៅសហរដ្ឋអាមេរិកអារ៉ាប់។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div id="about-me">
                                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBox1">
                                                                                    <div>
                                                                                        <div className="bg-game11">1</div>
                                                                                    </div>
                                                                                    <div className="bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginLft0 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-1.png" alt='Magic Icon' />
                                                                                    </div>

                                                                                    <div className="text-center margin-top2  ">
                                                                                        Purchase a Bottle for AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} to enter our weekly RAFFLE DRAW and MAIN DRAW in one
                                                                                        go!
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">2</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-2.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        Choose any {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} numbers between 1 to 36 per ticket. Or, you can click on the randomisation "
                                                                                        <img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" icon
                                                                                        and we select your numbers for you.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">3</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-3.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        Main Draw: Match them in ANY ORDER to win the Grand Prize of AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}*.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">4</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-4.png?V0.1" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">5</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game11">&nbsp;</div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-5.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        Draws take place weekly on Thursdays at 8 PM UAE Time, Live on our YouTube channel and Facebook page.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorderLast bg-game11">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game11">6</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2 aboutGameContentBoxW6 border-[1px] border-game11 marginRht0 rem9">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/magic-abt-6.png" alt='Magic Icon' />
                                                                                    </div>
                                                                                    <div className="text-center margin-top2  ">
                                                                                        By purchasing a water bottle on Gulf Ticket, you are assisting us in the development
                                                                                        of palm tree plantations in the United Arab Emirates.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}
function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AboutMgicFive))
