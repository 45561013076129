import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { accountActions } from '../../action/AccountAction';
import { loginActions } from "../../action/loginAction";
import { commonClass } from "../../helpers/commonClass";

class Withdraws extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            accountno: '',
            accountname: '',
            ifsccode: '',
            branchname: ''
        }
        this.props.getBalance(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);
    }
    handleChange = (e) => {
        try {
            if (e.target.name == 'amount') {
                if (e.target.value == '' || /^[0-9\b]+$/.test(e.target.value))
                    this.setState({ [e.target.name]: e.target.value });
            }
            else if (e.target.name == 'branchname') {
                this.setState({ [e.target.name]: e.target.value.replace(/[^a-z]/gi, '').toUpperCase() });
            }
            else if (e.target.name == 'accountname') {
                if (e.target.value == '' || /^[a-zA-Z ]*$/.test(e.target.value))
                    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
            }
            else if (e.target.name == 'ifsccode' || e.target.name == 'accountno') {
                if (e.target.value == '' || /^[a-zA-Z0-9_]+$/.test(e.target.value))
                    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
            }
            else {
                this.setState({ [e.target.name]: e.target.value.toUpperCase() });
            }
        }
        catch (ex) { }
    }
    getRandomNumbers() {
        const typedArray = new Uint8Array(5);
        const randomValues = window.crypto.getRandomValues(typedArray);
        return randomValues.join('');
    }
    btmWithdrawAmt = () => {
        if (this.state.amount) {
            if (parseInt(this.state.amount) < 200) {
                this.showMsg('lblMinWithdraw');
                return;
            }
            if (this.state.accountname) {
                if (this.state.accountno) {
                    if (this.state.ifsccode) {
                        // if (this.state.branchname) {
                        if (this.props.user && this.props.user.emailID == "") {
                            this.showMsg(this.props.t('lblrequiremail'));
                            return;
                        }
                        else {
                            let reqdata = { "type_id": 1, "amount": parseInt(this.state.amount * 100), "pg_type_id": 1, "pg_txn_id": this.getRandomNumbers(), "pg_ref_id": this.getRandomNumbers(), "pg_data": this.props.user.mobileNo, "item_description": this.props.user.username, "bank_name": this.state.accountname, "ac_number": this.state.accountno, "ifsc_code": this.state.ifsccode, "branch_name": this.state.branchname };
                            this.props.Cashoutmoney(reqdata, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
                            this.setState({
                                amount: '',
                                accountno: '',
                                accountname: '',
                                ifsccode: '',
                                branchname: ''
                            });
                        }
                        // }
                        // else {
                        //     this.showMsg(this.props.t('lblPleaseEnterbranch') + '.');
                        // }
                    }
                    else {
                        this.showMsg(this.props.t('lblPleaseEnterifsc'));
                    }
                }
                else {
                    this.showMsg(this.props.t('lblPleaseEnterAc'));
                }
            }
            else {
                this.showMsg(this.props.t('lblPleaseEnterbankname'));
            }
        }
        else {
            this.showMsg(this.props.t('lblPleaseEnterAmnt'));
        }
    }
    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }
    componentDidMount() {
        // localStorage.setItem('atabId', 4); this code is using in Account Component
    }
    render() {
        const { t } = this.props;
        return (
            <div className="profileCont">
                <div className="walletTrans">
                    <div className="col-md-6">
                        <div className="logReg">
                            <div className="form2">
                                <div className="paymentWithdraw">
                                    <div className="paymentnum">
                                        <span><img src="./assets/img/icons/coins_01.webp" /></span>
                                    </div>
                                    <div className="paymentCards">
                                        <p>{t('lblTotalWinners')}</p>
                                    </div>
                                </div>
                                <div className="paymentinp">{this.props.appvariables.currency} {commonClass.aedCrnyConvert(this.props.user.winbalance)}</div>
                                <div className="paymentWithdraw winningsSection">
                                    <div className="paymentnum">
                                        <span><img src="./assets/img/icons/pay_by_check.webp" /></span>
                                    </div>
                                    <div className="paymentCards marTop">
                                        <input type="text" className="form-control" id="txtAmount" placeholder={`${t('lblAmt')}*`} maxLength={5} name="amount" value={this.state.amount} onChange={(e) => this.handleChange(e)} autoComplete="off" />
                                        <small>{t('lblMinWithdrawAmt')}</small>
                                    </div>
                                </div>
                                <div className="paymentCards marTop">
                                    <input type="text" className="form-control" id="accountname" placeholder={`${t('bankname')}*`} maxLength={20} name="accountname" value={this.state.accountname} onChange={(e) => this.handleChange(e)} autoComplete="off" />
                                </div>
                                <div className="paymentCards marTop">
                                    <input type="text" className="form-control" id="accountNo" placeholder={`${t('accountNo')}*`} maxLength={34} name="accountno" value={this.state.accountno} onChange={(e) => this.handleChange(e)} autoComplete="off" />
                                </div>
                                <div className="paymentCards marTop">
                                    <input type="text" className="form-control" id="ifsccode" placeholder={`${t('ifcsecode')}*`} maxLength={20} name="ifsccode" value={this.state.ifsccode} onChange={(e) => this.handleChange(e)} autoComplete="off" />
                                </div>
                                <div className="paymentCards marTop">
                                    <input type="text" className="form-control" id="branchname" placeholder={`${t('branchname')} / ${t('lblCountry')}`} name="branchname" value={this.state.branchname} onChange={(e) => this.handleChange(e)} autoComplete="off" />
                                </div>

                                <div className="payNowBtn" id="withdrawButton">
                                    <button className="btn2" onClick={() => this.btmWithdrawAmt()}>{t('lblwithdraw')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="walletCusImage">
                            <img src="./assets/img/widthdraw.webp" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { user,appvariables } = state.loginReducer;
    return { user,appvariables };
}
const mapActionToProps = {
    alertError: alertActions.error,
    Cashoutmoney: accountActions.Cashoutmoney,
    getBalance: loginActions.getBalance
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Withdraws))