import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { appVariables } from '../../helpers/appVariables';

class AboutLuckyOne extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <section>
                <div className='wrap1'>
                    <div className="priceBraDwnTitle w-100">
                        {/* <h2>{i18n.language === 'ur' ? 'کے بارے میں ' : i18n.language === 'ml' ? 'നെകുറിച്ച്' : i18n.language === 'ar' ? 'حول اللعبة' :
                            i18n.language === 'th' ? 'เกี่ยวกับ' : i18n.language === 'tl' ? 'Tungkol sa' : i18n.language === 'es' ? 'Acerca de' :
                                i18n.language === 'ta' ? 'பற்றி' : i18n.language === 'hi' ? 'के बारे में' : 'About'} </h2> */}
                        <h2>{t('lblabouttxt')}</h2>
                        <img src="./assets/img/gamesBg/lucky-1-logo.png?v0.3" alt="lucky 1 logo" className="w-30" />
                    </div>
                    <div className='w-100'>
                        {i18n.language === 'ur' ?
                            <div id="about-me">
                                {/* <h2 className="aboutGameContentHead">کے بارے میں  <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="lucky1 logo" className="h-10 w-24" /></span></h2> */}
                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBox1">
                                            <div>
                                                <div className="bg-game13">1</div>
                                            </div>
                                            <div className="bg-game13"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                            </div>

                                            <div className="text-center  margin-top2">
                                                ہمارے ہفتہ وار ریفل ڈرا اور مین ڈرا میں ایک بار میں داخل ہونے کے لیے {appVariables.PRIZE_STRUCT_LUCKY1.BetAmount} اماراتی دینار میں بوتل خریدیں!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">2</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game13"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                            </div>
                                            <div className="text-center  margin-top2">
                                                {/* فی ٹکٹ 1 سے 36 کے درمیان کوئی بھی {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} نمبر منتخب کریں۔ یا، آپ رینڈمائزیشن "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" آئیکن پر کلک کر سکتے ہیں اور ہم آپ کے لیے آپ کے نمبر منتخب کرتے ہیں۔ */}
                                                فی ٹکٹ 1 سے 12 کے درمیان کوئی بھی 1 رقم/نمبر منتخب کریں۔ یا، آپ رینڈمائزیشن "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" آئیکن پر کلک کر سکتے ہیں اور ہم آپ کے لیے آپ کی رقم/نمبر منتخب کرتے ہیں۔
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">3</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game13"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                            </div>
                                            <div className="text-center  margin-top2">
                                            مین ڈرا: اگر رقم/نمبر مماثل ہے، تو آپ کی خریداری کی رقم کا متعدد جیتنے کے لیے۔
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game13">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game13"></div>
                                    </div>
                                    <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                        </div>
                                        <div className="text-center  margin-top2">
                                            ریفل ڈرا: ہر ہفتے {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} گارنٹی یافتہ فاتح! ہر فاتح AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} وصول کرتا ہے۔
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                            </div>
                                            <div className="text-center  margin-top2">
                                            قرعہ اندازی روزانہ ہوتی ہے، ہمارے یوٹیوب چینل اور فیس بک پیج پر لائیو۔
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">5</div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                            </div>
                                            <div className="text-center  margin-top2">
                                            گلف ٹکٹ پر پانی کی بوتل خرید کر، آپ متحدہ عرب امارات میں کھجور کے درختوں کے باغات کی ترقی میں ہماری مدد کر رہے ہیں۔
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            i18n.language === 'ml' ?
                                <div id="about-me">
                                    {/* <h2 className="aboutGameContentHead">നെകുറിച്ച് <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBox1">
                                                <div>
                                                    <div className="bg-game13">1</div>
                                                </div>
                                                <div className="bg-game13"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                </div>

                                                <div className="text-center  margin-top2">
                                                    ഞങ്ങളുപ്പട രപതിൊര റാഫിൾ ട്രഡാ ുിം പ്പമ ിൻ ട്രഡാ ുിം ഒറ്റ ടിക്ക് നൽകുന്നതിന് {appVariables.PRIZE_STRUCT_LUCKY1.BetAmount} ദിർഹത്തിന് ഒരു കുെി ൊങ്ങുക!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game13">2</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game13"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                ഓരോ ടിക്കറ്റിനും 1 മുതൽ 12 വരെ ഏതെങ്കിലും 1 രാശി/സംഖ്യ തിരഞ്ഞെടുക്കുക. അല്ലെങ്കിൽ, നിങ്ങൾക്ക് റാൻഡമൈസേഷൻ "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ഐക്കണിൽ ക്ലിക്ക് ചെയ്യാം, ഞങ്ങൾ നിങ്ങൾക്കായി നിങ്ങളുടെ രാശി/നമ്പർ തിരഞ്ഞെടുക്കും.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game13">3</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game13"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                പ്രധാന നറുക്കെടുപ്പ്: രാശി/സംഖ്യ പൊരുത്തപ്പെടുന്നെങ്കിൽ, നിങ്ങളുടെ വാങ്ങൽ തുകയുടെ ഗുണിതം നേടുന്നതിന്.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game13"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                റാഫിൾ ഡ്രോ: എല്ലാ ആഴ്‌ചയും {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} വിജയികൾ ഉറപ്പ്! ഓരോ വിജയിക്കും {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} ദിർഹം ലഭിക്കും.
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game13">4</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                നറുക്കെടുപ്പുകൾ ദിവസവും നടക്കുന്നു, ഞങ്ങളുടെ YouTube ചാനലിലും Facebook പേജിലും തത്സമയം.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game13">5</div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                ഗൾഫ് ടിക്കറ്റിൽ ഒരു വാട്ടർ ബോട്ടിൽ വാങ്ങുന്നതിലൂടെ, യുണൈറ്റഡ് അറബ് എമിറേറ്റിലെ ഈന്തപ്പനത്തോട്ടങ്ങളുടെ വികസനത്തിൽ നിങ്ങൾ ഞങ്ങളെ സഹായിക്കുന്നു.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                i18n.language === 'ar' ?
                                    <div id="about-me">
                                        {/* <h2 className="aboutGameContentHead">حول اللعبة <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBox1">
                                                    <div>
                                                        <div className="bg-game13">1</div>
                                                    </div>
                                                    <div className="bg-game13"></div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                    </div>

                                                    <div className="text-center  margin-top2">
                                                        قم بشراء زجاجة بقيمة {appVariables.PRIZE_STRUCT_LUCKY1.BetAmount} دراهم للدخول في السحب الأسبوعي والسحب الرئيسي دفعة واحدة!
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game13">2</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game13"></div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                        اختر أي برج/رقم واحد يتراوح بين 1 إلى 12 لكل تذكرة. أو يمكنك النقر على أيقونة "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" وسنختار لك برجك/رقمك.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game13">3</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game13"></div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                    السحب الرئيسي: إذا كان البرج/الرقم متطابقين، فستفوز بمضاعفات مبلغ الشراء.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game13">4</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game13"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                                </div>
                                                <div className="text-center  margin-top2">
                                                    : {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} فائزين مضمونين كل أسبوع! يحصل كل فائز على {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} درهم إماراتي.
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game13">4</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                    يتم إجراء السحب يوميًا، مباشرةً على قناتنا على اليوتيوب وصفحتنا على الفيسبوك.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game13">5</div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                    من خلال شراء زجاجة مياه من موقع Gulf Ticket، فإنك تساعدنا في تطوير مزارع أشجار النخيل في دولة الإمارات العربية المتحدة.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    i18n.language === 'th' ?
                                        <div id="about-me">
                                            {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBox1">
                                                        <div>
                                                            <div className="bg-game13">1</div>
                                                        </div>
                                                        <div className="bg-game13"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                        </div>

                                                        <div className="text-center  margin-top2">
                                                            ซื้อขวดในราคา 5 AED เพื่อเข้าร่วมการจับรางวัลหลักของเราในครั้งเดียว!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game13">2</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game13"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            เลือก 1 ราศี/หมายเลขระหว่าง 1 ถึง 12 ต่อตั๋ว หรือคุณสามารถคลิกที่ไอคอน " <img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" แบบสุ่ม แล้วเราจะเลือกราศี/หมายเลขของคุณให้กับคุณ
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game13">3</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game13"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            การจับรางวัลหลัก: หากราศี/หมายเลขตรงกัน เพื่อรับรางวัลทวีคูณของยอดซื้อของคุณ
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game13"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                            </div>
                                        </div>
                                    </div> */}
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game13">4</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            การจับรางวัลเกิดขึ้นทุกวัน ถ่ายทอดสดทางช่อง YouTube และหน้า Facebook ของเรา
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game13">5</div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            การซื้อขวดน้ำจาก Gulf Ticket ถือเป็นการช่วยเราในการพัฒนา
                                                            ของสวนปาล์มในสหรัฐอาหรับเอมิเรตส์
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :

                                        i18n.language === 'tl' ?
                                            <div id="about-me">
                                                {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBox1">
                                                            <div>
                                                                <div className="bg-game13">1</div>
                                                            </div>
                                                            <div className="bg-game13"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                            </div>

                                                            <div className="text-center  margin-top2">
                                                                Bumili ng Bote para sa AED 5 para makapasok sa aming MAIN DRAW nang sabay-sabay!
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game13">2</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game13"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                Pumili ng anumang 1 Zodiac/numero sa pagitan ng 1 hanggang 12
                                                                bawat tiket. O, maaari kang mag-click sa icon ng randomization na
                                                                "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" at piliin namin ang iyong Zodiac/numero para sa iyo.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game13">3</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game13"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                Pangunahing Draw: Kung ang Zodiac/numero ay tugma, upang manalo ng maramihang halaga ng iyong binili.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game13"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                            </div>
                                        </div>
                                    </div> */}
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game13">4</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                Nagaganap ang mga draw araw-araw , Live sa aming channel sa YouTube at Facebook page.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game13">5</div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                Sa pamamagitan ng pagbili ng bote ng tubig sa Gulf Ticket, tinutulungan mo kami sa pagbuo ng mga plantasyon ng palm tree sa United Arab Emirates.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            i18n.language === 'es' ?
                                                <div id="about-me">
                                                    {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBox1">
                                                                <div>
                                                                    <div className="bg-game13">1</div>
                                                                </div>
                                                                <div className="bg-game13"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                </div>

                                                                <div className="text-center  margin-top2">
                                                                    ¡Compre una botella por AED 5 para participar en nuestro SORTEO PRINCIPAL de una sola vez!
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game13">2</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game13"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Elija cualquier 1 Zodíaco/número entre 1 y 12 por boleto.
                                                                    O puede hacer clic en el ícono de aleatorización "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />"
                                                                    y seleccionaremos su zodíaco/número por usted.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game13">3</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game13"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Sorteo principal: si el zodíaco/número coincide, ganará el múltiplo del monto de su compra.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game13">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game13"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game13">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                            </div>
                                        </div>
                                    </div> */}
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game13">4</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Los sorteos se realizan todos los días, en vivo en nuestro canal de YouTube y página de Facebook.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game13">5</div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Al comprar una botella de agua en Gulf Ticket, nos estás ayudando en el
                                                                    desarrollo de plantaciones de palmeras en los Emiratos Árabes Unidos.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                i18n.language === 'ta' ?
                                                    <div id="about-me">
                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBox1">
                                                                    <div>
                                                                        <div className="bg-game13">1</div>
                                                                    </div>
                                                                    <div className="bg-game13"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                    </div>

                                                                    <div className="text-center  margin-top2">
                                                                        ஒரே நேரத்தில் எங்கள் முதன்மை டிராவில் நுழைய AED 5 க்கு ஒரு பாட்டிலை வாங்கவும்!
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game13">2</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game13"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        ஒரு டிக்கெட்டுக்கு 1 முதல் 12 வரை ஏதேனும் 1 ராசி/எண்ணைத் தேர்வு செய்யவும்.
                                                                        அல்லது, நீங்கள் ரேண்டமைசேஷன் "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ஐகானைக் கிளிக் செய்யலாம்,
                                                                        உங்களுக்கான ராசி/எண்ணைத் தேர்ந்தெடுக்கிறோம்.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game13">3</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game13"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        மெயின் டிரா: ராசி/எண் பொருந்தியிருந்தால், நீங்கள் வாங்கிய தொகையின் பெருக்கத்தை வெல்ல.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game13">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game13"></div>
                                    </div>
                                    <div className="aboutGameContentBox2   border-[2px] border-game13">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                        </div>
                                        <div className="text-center  margin-top2">
                                            Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                        </div>
                                    </div>
                                </div> */}
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game13">4</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        டிராக்கள் தினமும் நடைபெறும், எங்கள் யூடியூப் சேனல் மற்றும் பேஸ்புக் பக்கத்தில் நேரலை.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game13">5</div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        வளைகுடா பயணச்சீட்டில் தண்ணீர் பாட்டிலை வாங்குவதன் மூலம், ஐக்கிய அரபு எமிரேட்ஸில் பனை மரத் தோட்டங்களை மேம்படுத்த எங்களுக்கு உதவுகிறீர்கள்.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    i18n.language === 'hi' ?
                                                        <div id="about-me">
                                                            {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBox1">
                                                                        <div>
                                                                            <div className="bg-game13">1</div>
                                                                        </div>
                                                                        <div className="bg-game13"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                        </div>

                                                                        <div className="text-center  margin-top2">
                                                                            एक बार में हमारे मुख्य ड्रॉ में प्रवेश करने के लिए AED 5 में एक बोतल खरीदें!
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game13">2</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game13"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                            प्रति टिकट 1 से 12 के बीच कोई भी 1 राशि/संख्या चुनें। या,
                                                                            आप रैंडमाइजेशन "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" आइकन पर क्लिक कर सकते हैं और हम आपके लिए
                                                                            आपकी राशि/नंबर का चयन करते हैं।

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game13">3</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game13"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                            मुख्य ड्रा: यदि राशि/संख्या मेल खाती है, तो अपनी खरीद राशि का गुणक जीतने के लिए।
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game13">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game13"></div>
                                    </div>
                                    <div className="aboutGameContentBox2   border-[2px] border-game13">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                        </div>
                                        <div className="text-center  margin-top2">
                                            Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                        </div>
                                    </div>
                                </div> */}
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game13">4</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                            ड्रॉ प्रतिदिन होते हैं, हमारे यूट्यूब चैनल और फेसबुक पेज पर लाइव होते हैं।
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game13">5</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                            गल्फ टिकट पर पानी की बोतल खरीदकर, आप संयुक्त अरब अमीरात में ताड़ के पेड़ के बागानों के विकास में हमारी सहायता कर रहे हैं।
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        i18n.language === 'vi' ?
                                                            <div id="about-me">
                                                                {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBox1">
                                                                            <div>
                                                                                <div className="bg-game13">1</div>
                                                                            </div>
                                                                            <div className="bg-game13"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                            </div>

                                                                            <div className="text-center  margin-top2">
                                                                                Mua một chai nước với giá 5 AED để tham gia XỔ SỐ và XỔ SỐ CHÍNH của chúng tôi chỉ trong một lần!
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game13">2</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game13"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                Chọn bất kỳ 1 cung hoàng đạo/số nào từ 1 đến 12 trên mỗi vé. Hoặc, bạn có thể nhấp vào biểu tượng "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />"
                                                                                ngẫu nhiên và chúng tôi sẽ chọn Cung hoàng đạo/số cho bạn.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game13">3</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game13"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                Rút thăm chính: Nếu Hoàng đạo/số trùng khớp, bạn sẽ thắng bội số của số tiền mua hàng.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game13">4</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                Rút thăm diễn ra hàng ngày, Trực tiếp trên kênh YouTube và trang Facebook của chúng tôi.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game13">5</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                {/* Xổ Số Chính: Khớp các số theo bất kỳ thứ tự nào để giành Giải Thưởng Lớn trị giá {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} AED* */}
                                                                                Bằng cách mua một chai nước trên Gulf Ticket, bạn đang hỗ trợ chúng tôi trong việc phát triển các đồn điền cây cọ ở Các Tiểu vương quốc Ả Rập Thống nhất.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            i18n.language === 'id' ?
                                                                <div id="about-me">
                                                                    {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBox1">
                                                                                <div>
                                                                                    <div className="bg-game13">1</div>
                                                                                </div>
                                                                                <div className="bg-game13"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                                </div>

                                                                                <div className="text-center  margin-top2">
                                                                                    Beli Botol seharga AED 5 untuk mengikuti UNDIAN UTAMA kami sekaligus!
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game13">2</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game13"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    Pilih 1 Zodiak/angka antara 1 hingga 12 per tiket. Atau, Anda dapat mengklik ikon pengacakan
                                                                                    "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" dan kami akan memilih Zodiak/nomor Anda untuk Anda.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game13">3</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game13"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    Undian Utama: Jika Zodiak/angka cocok, untuk memenangkan kelipatan jumlah pembelian Anda.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game13">4</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    Pengundian dilakukan setiap hari, Langsung di saluran YouTube dan halaman Facebook kami.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game13">5</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    Dengan membeli satu botol air di Gulf Ticket, Anda membantu kami dalam pengembangan perkebunan pohon palem di Uni Emirat Arab.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                i18n.language === 'km' ?
                                                                    <div id="about-me">
                                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBox1">
                                                                                    <div>
                                                                                        <div className="bg-game13">1</div>
                                                                                    </div>
                                                                                    <div className="bg-game13"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>

                                                                                    <div className="text-center  margin-top2">
                                                                                        ទិញដបទឹកមួយក្នុងតម្លៃ 5 AED ដើម្បីចូលរួមក្នុងការចាប់ឆ្នោត RAFFLE និងឆ្នោតចម្បងប្រចាំសប្តាហ៍របស់យើងក្នុងពេលតែមួយ!
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">2</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game13"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        ជ្រើសរើស 1 Zodiac / លេខណាមួយរវាង 1 ដល់ 12 ក្នុងមួយសំបុត្រ។ ឬអ្នកអាចចុចលើរូបតំណាង
                                                                                        "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ចៃដន្យ ហើយយើងជ្រើសរើសរាសីចក្រ/លេខរបស់អ្នកសម្រាប់អ្នក។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">3</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game13"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        ការចាប់រង្វាន់ចម្បង៖ ប្រសិនបើលេខ/រាសីចក្រត្រូវគ្នា ដើម្បីឈ្នះច្រើននៃចំនួនទិញរបស់អ្នក។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">4</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        ការចាប់ឆ្នោតធ្វើឡើងជារៀងរាល់ថ្ងៃ ផ្សាយផ្ទាល់នៅលើបណ្តាញ YouTube និងទំព័រ Facebook របស់យើង។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">5</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        ដោយការទិញដបទឹកមួយលើ Gulf Ticket អ្នកកំពុងជួយគាំទ្រយើងក្នុងការអភិវឌ្ឍដំណាំដើមត្នោតនៅសហរដ្ឋអាមេរិកអារ៉ាប់។
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div id="about-me">
                                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBox1">
                                                                                    <div>
                                                                                        <div className="bg-game13">1</div>
                                                                                    </div>
                                                                                    <div className="bg-game13"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginLft0 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>

                                                                                    <div className="text-center  margin-top2">
                                                                                        Purchase a Bottle for AED 5 to enter our MAIN DRAW in one
                                                                                        go!
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">2</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game13"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        Choose any 1 Zodiac/number between 1 to 12 per ticket. Or, you can click on the randomisation "
                                                                                        <img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" icon
                                                                                        and we select your Zodiac/number for you.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">3</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game13"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-3.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        Main Draw: If Zodiac/number is matched, to win the multiple of your purchase amount.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">4</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game13">&nbsp;</div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-4.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        Draws take place daily , Live on our YouTube channel and Facebook page.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorderLast bg-game13">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game13">5</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game13 marginRht0 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/lucky-abt-5.png?v0.1v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        By purchasing a water bottle on Gulf Ticket, you are assisting us in the development
                                                                                        of palm tree plantations in the United Arab Emirates.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                        }
                    </div>
                </div >
            </section>
        )
    }
}
function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AboutLuckyOne))
