import React, { Component } from 'react';
import { history } from '../../component/helpers/history';
import { withTranslation } from 'react-i18next';
class Footer extends Component {
    constructor(props) {
        super(props);
    }
    changeTab = (tabid) => {
        localStorage.setItem('tabId', tabid);
        history.push('/generalinfo');
    }
    render() {
        const { t } = this.props;
        return (
            <footer className={` ${this.props.Pageloc == 'fortune4' || this.props.Pageloc == 'super6' || this.props.Pageloc == 'magic5' || this.props.Pageloc == 'lucky1' || this.props.Pageloc == 'dream5' || this.props.Pageloc ==  'home' ||this.props.Pageloc == ""  ?   'padd-Top-175' : ''} `}>
                <div className="wrap1 footerWidth">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <img src="./assets/img/foot-logo.png?v0.2" alt='Footer Logo Image' />
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <ul className='footMenu'>
                            {/* <li><a onClick={() => this.changeTab(1)}>{t('lblAboutFooter')}</a></li> */}
                            <li><a onClick={() => this.changeTab(1)}>{t('lblAbout')}</a></li>
                            {/* <li><a onClick={() => this.changeTab(7)}>{t('lblGivingBack')}</a></li> */}
                            {/* <li><a onClick={() => this.changeTab(7)}>{t('lblGivingBack')}</a></li> */}
                            {/* <li><a onClick={() => history.push('/promotion')} >{t('lblPromotion')}</a></li> */}
                            {/* <li><a onClick={() => { history.push('/generalinfo', { gtid: 0 }) }}>{t('lblNews')}</a></li> */}
                            {/* <li><a onClick={() => { history.push('/generalinfo', { gtid: 0 }) }}>{t('lblPromotion')}</a></li> */}
                            <li><a onClick={() => this.changeTab(9)}>{t('lblParticipateResponsible')}</a></li>
                            <li><a onClick={() => this.changeTab(11)}>{t('lblGamesRules')}</a></li>
                            {/* <li><a onClick={() => this.changeTab(8)}>{t("lblCSR")}</a></li> */}
                            {/* <li><a onClick={() => this.changeTab(10)} style={{ "border-right": "none" }}>{t('lblLaunchoffer')}</a></li> */}
                            {/* </ul>
                        <ul className="footMenu footMenu2"> */}
                            <li><a onClick={() => this.changeTab(6)}>{t('lblLegal')}</a></li>
                            <li><a onClick={() => this.changeTab(4)}>{t('lblTerms')}</a></li>
                            {/* style={{ "border-right": "none" }} */}
                            <li><a onClick={() => this.changeTab(5)} >{t('lblPrivacyFooter')}</a></li>
                            <li><a onClick={() => this.changeTab(14)} >{t('lblFAQs')}</a></li>
                            <li><a onClick={() => this.changeTab(15)} >{t('lblnewsletter')}</a></li>
                        </ul>
                    </div>
                    {/* <div className="col-md-12 col-sm-12 col-xs-12">
                        <ul className='footMenu'>
                            <li><a onClick={() => this.changeTab(12)} style={{ "border-right": "none" }}>{t('lblContactus')}</a></li>
                        </ul>
                    </div> */}
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="quickLinks">
                            {/* <img src="./assets/img/icons/social/contact-us.png" alt='contact-us icon'/> */}
                            <div onClick={() => this.changeTab(12)}><a ><span>{t('lblContactus')}</span></a></div>
                            {/* <img src="./assets/img/icons/social/feedback.png" alt='feedback icon'/> */}
                            <div onClick={() => this.changeTab(13)}><a href="/feedback"><span>{t('lblFeedback')}</span></a></div>
                        </div>
                    </div>
                </div>
                <div className="footTop">
                    <div className="wrap1">
                        <p>&copy; 2024 {t('lblGulfTicket')}. {t('lblAllRightReserved')}.</p>
                        <span>{t('lblfooterinfo')}</span>
                        <ul className="social">
                            <li><a href="https://www.youtube.com/@gulfticket" target="_blank" rel="noopener"><img src="./assets/img/icons/social/youtube.png?v0.1" alt='youtube icon' /></a></li>
                            <li><a href="https://www.facebook.com/profile.php?id=61555927626606" target="_blank" rel="noopener"><img src="./assets/img/icons/social/fb.png?v0.1" alt='fb icon' /></a></li>
                            <li><a href="https://twitter.com/gulfticketindia" target="_blank" rel="noopener"><img src="./assets/img/icons/social/twitter-x.png?v0.1" alt='twitter icon' /></a></li>
                            <li><a href="https://www.instagram.com/gulfticket_india/" target="_blank" rel="noopener"><img src="./assets/img/icons/social/insta.png?v0.1" alt='insta icon' /></a></li>
                            {/* <li><a href="https://www.tiktok.com/@gulfticket" target="_blank" rel="noopener"><img src="./assets/img/icons/social/tiktok.png" alt='tiktok icon'/></a></li> */}
                        </ul>
                    </div>
                </div>
            </footer>
        );
    }
}
export default (withTranslation()(Footer));