import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { accountActions } from '../../action/AccountAction';
import CryptoJS from 'crypto-js';
import { loginActions } from '../../action/loginAction';
import { history } from '../../helpers/history';
import { commonClass } from "../../helpers/commonClass";

let deviceId = "~1"; //1- for web , 2-for-mobileapp 3 - for mobileWeb
let payType = "~1"; //1- for wallet, 2-buy

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.iframwrefs = React.createRef();
        let lreqdata = CryptoJS.AES.encrypt(this.props.user.mobileNo + "~" + this.props.user.loginKey + "~" + this.props.user.Country_code.replaceAll("+", "") + "~" + this.props.user.balance + "~0~" + this.props.i18n.language + deviceId + payType + "~0" + "~" + process.env.REACT_APP_DESKTOP_URL + "~0", "U2FsdGVkX1/dTA00hg0swnksKTyUdhPWxhPpY1CEqEs=");
        this.state = {
            redirecturl: process.env.REACT_APP_WALLET_URL + 'home?data=' + lreqdata.toString().replaceAll("+", "@"),
            balWait: false,
            cbal: this.props.user.balance,
            setEvnt: false,
            showloader: true
        }
        this.props.getBalance(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);
    }
    getbal = () => {
        //due to payment popup oepn in same tab ,so the page laaded again so,bal call at constrator
        //this.props.getBalance(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);
        this.state.balWait = true;
        this.state.cbal = this.props.user.balance;
        let gotcartflog = localStorage.getItem('isRedirectToCart');
        if (gotcartflog == 1) {
            localStorage.removeItem('isRedirectToCart');
            setTimeout(() => {
                history.push('/payment');
            }, 500);
        }
        else {
            this.resetPGURL(); //reload if user close the pg page manually
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.user && (this.state.cbal != this.props.user.balance || this.state.balWait)) {
            this.state.cbal = this.props.user.balance;
            this.state.balWait = false;
            // let lreqdata = CryptoJS.AES.encrypt(this.props.user.mobileNo + "~" + this.props.user.loginKey + "~" + this.props.user.Country_code.replaceAll("+", "") + "~" + this.props.user.balance + "~0~" + this.props.i18n.language + deviceId + payType, "U2FsdGVkX1/dTA00hg0swnksKTyUdhPWxhPpY1CEqEs=");
            // let urlNew = process.env.REACT_APP_WALLET_URL + lreqdata.toString().replaceAll("+", "@");
            // this.setState({ redirecturl: urlNew });
            this.resetPGURL();
        }
        this.onLoadMyVirtual();
    }
    resetPGURL = () => {
        let lreqdata = CryptoJS.AES.encrypt(this.props.user.mobileNo + "~" + this.props.user.loginKey + "~" + this.props.user.Country_code.replaceAll("+", "") + "~" + this.props.user.balance + "~0~" + this.props.i18n.language + deviceId + payType + "~0" + "~" + process.env.REACT_APP_DESKTOP_URL + "~0", "U2FsdGVkX1/dTA00hg0swnksKTyUdhPWxhPpY1CEqEs=");
        let urlNew = process.env.REACT_APP_WALLET_URL + 'home?data=' + lreqdata.toString().replaceAll("+", "@");
        this.setState({ redirecturl: urlNew });
    }
    componentDidMount() {
        if (this.props.locationData) {
            const query = new URLSearchParams(this.props.locationData.search);
            const token = query && query.get('pn')
            if (token) {
                const token1 = CryptoJS.AES.decrypt(token.replaceAll("@", "+").replaceAll(" ", "+"), "U2FsdGVkX1/dTA00hg0swnksKTyUdhPWxhPpY1CEqEs=").toString(CryptoJS.enc.Utf8);
                const ft = token1.split("~");
                if (ft && ft.length > 1) {
                    this.getbal();
                }
            }
        }
        //due to payment popup oepn in same tab
        //window.updateMyBalance(this.getbal);
        //window.addEventListener('message', window.receiveMessage, false);

        //// localStorage.setItem('atabId', 3); this code is using in Account Component
        this.onLoadMyVirtual();
    }
    showMsg = (Msg) => { this.props.alertError(Msg); }

    onLoadMyVirtual = () => {
        if (this.iframwrefs.current && this.state.setEvnt != true) {
            this.state.setEvnt = true;
            var iframe = this.iframwrefs.current;
            if (this.props.user.mobileNo != 0) { // only for logged in players
                let mythis = this;
                if (iframe.attachEvent) { // for IE
                    iframe.attachEvent("onload", function () {
                        console.log("iframe.attachEvent")
                        mythis.setState({ showloader: false });
                    });
                }
                else { // for Non IE
                    iframe.onload = function () {
                        console.log("iframe.onload")
                        mythis.setState({ showloader: false });
                    };
                }
            }
            let mythis1 = this;
            let tmr1 = setTimeout(
                () => {
                    if (mythis1.state.showloader) { mythis1.setState({ showloader: false }); }
                    clearTimeout(tmr1);
                }
                , 4000)
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="profileCont" >  {/* style={{ "padding": "0px" }} */}
                    <div className="transaction">
                        <div className="row marTop">
                            <div className="col-md-12">
                                <div className="transorder">  
                                    <div className="transButton">
                                        <p>{t('lblcredits')}</p>
                                        <div className="transBtnSel">
                                            <span className="currencyFormat"> {this.props.appvariables.currency} </span>
                                            <span className="numbers">{(this.props.user.balance - this.props.user.winbalance) > 0 ? commonClass.aedCrnyConvert(this.props.user.balance - this.props.user.winbalance) : 0}</span>
                                        </div>
                                    </div>
                                    <div className="transButton">
                                        <p>{t('lblWinnings')}</p>
                                        <div className="transBtnSel">
                                            <span className="currencyFormat"> {this.props.appvariables.currency} </span>
                                            <span className="numbers">{commonClass.aedCrnyConvert(this.props.user.winbalance)}</span>
                                        </div>
                                    </div>
                                    <div className="trnasactionBtns">
                                        {/* <button type="button" className="uppercase" onClick={() => { this.props.changeTab(3) }} id="show-transfer">{t('lblCredit')}</button> */}
                                        <button type="button" className="uppercase" onClick={() => { this.props.changeTab(4) }}>{t('lbldebit')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="divBorder marginTop dividerWidth"></div>

                        {this.state.showloader &&
                            <div id="divLoading" style={{ position: "absolute" }}>
                                <div id="center">
                                    <div className="lds-dual-ring"><div></div><div></div></div>
                                </div>
                            </div>
                        }
                        <iframe ref={this.iframwrefs} src={this.state.redirecturl} width="100%" height="550" style={{ "border": "none" }}></iframe>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user,appvariables } = state.loginReducer;
    return { user,appvariables };
}
const mapActionToProps = {
    alertError: alertActions.error,
    Cashdesposite: accountActions.Cashdesposite,
    getBalance: loginActions.getBalance,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Wallet))